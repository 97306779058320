import React from "react";
import {
	ContentContainer,
	EventWrapper,
	EventTextWrapper,
	EventTitle,
	EventDate,
	EventPicWrapper,
	EventPic,
	TableContainer,
	Table,
	TableHeader,
	TableRow,
	TableCell,
	ProgramCell,
	PendingStatus,
	ConfirmedStatus,
	CancelStatus,
	FixedBottomContainer,
	CheckInStatus,
} from "../../styles/Admin";
import { SubmitButton } from "../../styles/SignUp.js";
import { Link, useNavigate } from "react-router-dom";

const CheckinClass = ({
	data,
	selectCheckin,
	setSelectCheckin,
	handleCheckin,
}) => {
	const navigate = useNavigate();
	return (
		<>
			<ContentContainer>
				<TableContainer>
					<Table>
						<thead>
							<TableRow>
								<TableHeader>Check in Status</TableHeader>
								<TableHeader>Last Name</TableHeader>
								<TableHeader>First Name</TableHeader>
								<TableHeader>Gender</TableHeader>
								<TableHeader>Date of birth</TableHeader>
								<TableHeader>Order ID</TableHeader>
								<TableHeader>Contact</TableHeader>

								{/* Add other headers here */}
							</TableRow>
						</thead>
						<tbody>
							{data?.map((course) => (
								<TableRow
									key={course.order_id}
									onClick={() => {
										if (
											selectCheckin?.class_attendence_id ===
											course.attendenceDetails[0].class_attendence_id
										) {
											setSelectCheckin(null); // or some other default value
										} else {
											setSelectCheckin(course.attendenceDetails[0]);
										}
									}}
									style={
										selectCheckin?.class_attendence_id ===
										course.attendenceDetails[0].class_attendence_id
											? { backgroundColor: "lightgray" }
											: {}
									}
								>
									<TableCell>
										{course.attendenceDetails[0].status === 1 ? (
											<CheckInStatus backgroundColor="green"></CheckInStatus>
										) : course.attendenceDetails[0].status === 0 ? (
											<CheckInStatus backgroundColor="red"></CheckInStatus>
										) : (
											<CheckInStatus backgroundColor="grey"> </CheckInStatus>
										)}
									</TableCell>
									<TableCell>{course.participant_info?.lastname}</TableCell>
									<TableCell>{course.participant_info?.firstname}</TableCell>
									<TableCell>{course.participant_info?.gender}</TableCell>
									<TableCell>{course.participant_info?.dob}</TableCell>
									<TableCell>{course.order_id}</TableCell>
									<TableCell>
										<ProgramCell>
											<p>{course.orderDetails.contact}</p>
											<p>{course.orderDetails.email}</p>
										</ProgramCell>
									</TableCell>
									{/* Add other cells here */}
								</TableRow>
							))}
						</tbody>
					</Table>
				</TableContainer>
			</ContentContainer>
		</>
	);
};

export default CheckinClass;
