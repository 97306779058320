import React, { useRef, useState, useEffect } from "react";

import { BodyContainer2 } from "../../styles/GlobalStyles.jsx";

import Registration from "../registrationComponent/Registration.jsx";

import Students from "./Students.jsx";

const ChangingData = ({ data }) => {
	console.log(data);
	return (
		<BodyContainer2>
			{/* Flight related content */}
			{data && <Students data={data} />}
		</BodyContainer2>
	);
	// return (
	// 	<BodyContainer2 {...handlers}>
	// 		{/* Default content or error message */}
	// 	</BodyContainer2>
	// );
};
export default ChangingData;
