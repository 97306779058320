import React, { useRef, useState, useEffect } from "react";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import { TopbarInsideModule } from "../../components/topbar/TopbarInsideModule.jsx";

import StickyTopTab from "../../components/stickyTopTab/StickyTopTab.jsx";

import axios from "axios";

import { BodyContainer2 } from "../../styles/GlobalStyles.jsx";

import { SubmitButton } from "../../styles/SignUp.js";

import {
	ContentContainer,
	EventWrapper,
	EventTextWrapper,
	EventTitle,
	EventDate,
	EventPicWrapper,
	EventPic,
	TableContainer,
	Table,
	TableHeader,
	TableRow,
	TableCell,
	ProgramCell,
	PendingStatus,
	ConfirmedStatus,
	FixedBottomContainer,
} from "../../styles/Admin";

import { LoadingOverlay, LoadingSpinner } from "../../styles/GlobalStyles.jsx";

import StickyBottomCourseBar from "../../components/stickyBottomActionBar/stickyBottomCourseBar";

import MessageModal from "../../components/modal/Modal.jsx";

import { InputContainer, FormLabel } from "../../styles/SignUp.js";

import { RightArrow, Camera, AddSVG, Edit } from "../../assets/Calender.js";

import ToggleStatusButton from "../../components/statusButton/ToggleStatusButton.jsx";

import IconDeleteOutline from "../../components/svg/IconDeleteOutline.js";

const Courses = ({
	data,
	setModalOpen,
	setModalTemplate,
	selectCourse,
	setSelectCourse,
}) => {
	const [courses, setCourses] = useState(data.courses);
	const [editCourse, setEditCourse] = useState(false);

	const [loadingCircle, setLoadingCircle] = useState(false);

	useEffect(() => {
		setCourses(data.courses);
	}, [data]);

	const handleAddCourse = () => {
		setModalOpen(true);
		setModalTemplate("addCourse");
	};

	return (
		<BodyContainer2>
			<ContentContainer>
				<TableContainer>
					<Table>
						<thead>
							<TableRow>
								<TableHeader>Status</TableHeader>
								<TableHeader>Course code</TableHeader>
								<TableHeader>Option 1</TableHeader>
								<TableHeader>Option 2</TableHeader>
								<TableHeader>Course fee</TableHeader>
								<TableHeader>No. of classes</TableHeader>
								<TableHeader>Capacity</TableHeader>
								{/* Add other headers here */}
							</TableRow>
						</thead>
						<tbody>
							{courses?.map((course) => (
								<TableRow
									key={course.course_id}
									onClick={() => {
										if (selectCourse === course) {
											setSelectCourse(null); // or some other default value
										} else {
											setSelectCourse(course);
										}
									}}
									style={
										selectCourse === course
											? { backgroundColor: "lightgray" }
											: {}
									}
								>
									<TableCell>
										{course.status ? (
											<ConfirmedStatus>Open</ConfirmedStatus>
										) : (
											<PendingStatus>Closed</PendingStatus>
										)}
									</TableCell>
									<TableCell>{course.code}</TableCell>
									<TableCell>{course.option1value}</TableCell>
									<TableCell>{course.option2value}</TableCell>
									<TableCell>{course.fee}</TableCell>
									<TableCell>{course.noofclasses}</TableCell>
									<TableCell>{course.capacity}</TableCell>
									{/* Add other cells here */}
								</TableRow>
							))}
						</tbody>
					</Table>
				</TableContainer>
			</ContentContainer>

			{/* Sticky Bottom Bar */}

			<FixedBottomContainer>
				{!selectCourse ? (
					<SubmitButton onClick={handleAddCourse}>
						<AddSVG />
						Add Course
					</SubmitButton>
				) : (
					<SubmitButton onClick={handleAddCourse}>
						<Edit />
						Edit Course
					</SubmitButton>
				)}
			</FixedBottomContainer>

			{/* <StickyBottomCourseBar
				setModalOpen={setModalOpen}
				setModalTemplate={setModalTemplate}
			/> */}

			{/* {modalOpen && (
				<MessageModal
					isOpen={modalOpen}
					template={modalTemplate}
					language={"en"}
					autoClose={autoClose}
					setModalOpen={setModalOpen}
					// formData={student}
					// setFormData={setStudent}
				/>
			)} */}
			{/*************************************** 
            // LOADING CIRCLE //
        ***************************************/}
			{loadingCircle && (
				<LoadingOverlay>
					<LoadingSpinner />
				</LoadingOverlay>
			)}
		</BodyContainer2>
	);
};

const ProgramCourses = () => {
	const TabContext = React.createContext();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	// const fields = queryParams.get("field").split(",");
	// const id = location.pathname.split("/")[4];

	const { id } = useParams();

	const [currentTab, setCurrentTab] = useState("Overview");
	const [tabValues, setTabValues] = useState([
		{
			name: "tab_1",
			label: "Overview",
			link: `/eventAdmin/admin/program/${id}`,
		},
		{
			name: "tab_2",
			label: "Courses",
			link: `/eventAdmin/admin/program/${id}/courses`,
		},
		// {
		// 	name: "tab_3",
		// 	label: "Classes",
		// },
	]);

	// Set currentTab based on the current URL path
	useEffect(() => {
		const matchingTab = tabValues?.find(
			(tab) => tab.link === location.pathname
		);
		if (matchingTab) {
			setCurrentTab(matchingTab.label);
		}
	}, [location.pathname, tabValues]);

	const navigate = useNavigate();

	const minDate = new Date();

	console.log(minDate);

	const [data, setData] = useState({});
	const [formErrors, setFormErrors] = useState({});
	const [loadingCircle, setLoadingCircle] = useState(false);
	const [updatedData, setUpdatedData] = useState({});

	const [formData, setFormData] = useState(null);
	const [courseCreated, setCourseCreated] = useState(false);
	const [selectCourse, setSelectCourse] = useState(null);

	const [isFormInitialized, setIsFormInitialized] = useState(false);

	useEffect(() => {
		const initializeForm = () => {
			// Initialize student form fields based on setting
			setFormData({
				course_code: "",
				course_capacity: "",
				course_option1value: "",
				course_option2value: "",
				course_noofclasses: "",
				course_fee: "",
				course_open: false,
			});

			if (selectCourse) {
				setFormData({
					course_code: selectCourse.code,
					course_capacity: selectCourse.capacity,
					course_option1value: selectCourse.option1value,
					course_option2value: selectCourse.option2value,
					course_noofclasses: selectCourse.noofclasses,
					course_fee: selectCourse.fee,
					course_open: selectCourse.status,
				});
				console.log(formData);
			}

			setIsFormInitialized(true);
		};

		initializeForm();
	}, [data, selectCourse]); // Run this effect when courseId changes

	//Get the Program
	useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await axios.get(
					`/eventAdmin/api/class/getProgram?id=${id}`
				);

				setData(res.data);

				console.log(data);

				const fields = queryParams.get("field").split(",");
				for (let field of fields) {
					let fieldValue = res.data[field];

					if (field === "duedate") {
						fieldValue =
							moment(fieldValue).format("YYYY-MM-DD HH:mm:ss") ||
							moment(new Date())
								.hour(12)
								.minute(0)
								.second(0)
								.format("YYYY-MM-DD HH:mm:ss");
					}

					setUpdatedData((prev) => ({
						...prev,
						[field]: fieldValue,
					}));
				}
			} catch (err) {
				console.log(err);
			}
		};

		fetchData();
	}, [courseCreated]);

	const handleFilterChange = (name) => {
		console.log(name);
		// setCurrentTab(name);

		// if (name === "All Events") {
		// 	setTabValues("");
		// } else {
		// 	setFilter(name);
		// }
	};

	const [modalOpen, setModalOpen] = useState(false);
	const [autoClose, setAutoClose] = useState(false);
	const [modalTemplate, setModalTemplate] = useState("");

	// Add Course
	const handleSubmit = async () => {
		formData.program_id = id;

		// Add Course
		if (!selectCourse) {
			try {
				const res = await axios.post(
					"/eventAdmin/api/class/createCourse",
					formData
				);
				setModalOpen(true);
				setModalTemplate("success");
				// Update courseCreated state variable
				setCourseCreated((prevState) => !prevState);

				// setTimeout(() => {
				// 	navigate(`/eventAdmin/admin/program/${res.data.programId}`);
				// }, 3000);
			} catch (err) {
				setFormErrors(err.response.data);
			}
		} else {
			try {
				const res = await axios.put(
					`/eventAdmin/api/class/editCourse/${selectCourse.course_id}`,
					formData
				);
				setModalOpen(true);
				setModalTemplate("success");
				// Update courseCreated state variable
				setCourseCreated((prevState) => !prevState);
				setSelectCourse(null);

				// setTimeout(() => {
				// 	navigate(`/eventAdmin/admin/program/${res.data.programId}`);
				// }, 3000);
			} catch (err) {
				setFormErrors(err.response.data);
			}
		}
	};

	console.log(selectCourse);

	return (
		<>
			<TopbarInsideModule
				location={data.name}
				goBack={true}
				link={`/eventAdmin/admin/programmes`}
			/>
			<TabContext.Consumer>
				{(tab) => (
					<>
						{tabValues ? (
							<StickyTopTab
								tabValues={tabValues}
								tabValue={currentTab}
								changeTabAction={handleFilterChange}
								currentTab={currentTab}
								setCurrentTab={setCurrentTab}
							/>
						) : null}
					</>
				)}
			</TabContext.Consumer>
			<Courses
				data={data}
				setModalOpen={setModalOpen}
				setModalTemplate={setModalTemplate}
				selectCourse={selectCourse}
				setSelectCourse={setSelectCourse}
			/>
			{/* <SearchSortBar /> */}
			{/* // <ProgramChangingContent tab={currentTab} data={data} /> */}

			{modalOpen && (
				<MessageModal
					isOpen={modalOpen}
					template={modalTemplate}
					language={"en"}
					autoClose={autoClose}
					setModalOpen={setModalOpen}
					formData={formData}
					setFormData={setFormData}
					handleSubmit={handleSubmit}
					selectCourse={selectCourse}
				/>
			)}
		</>

		// 	{/***************************************
		//     // LOADING CIRCLE //
		// ***************************************/}
		// 	{loadingCircle && (
		// 		<LoadingOverlay>
		// 			<LoadingSpinner />
		// 		</LoadingOverlay>
		// 	)}
		// // </>
	);
};

export default ProgramCourses;
