import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AddSVG from "../../assets/AddSVG.js";
import { Home, Event, Student, Order, Checkin } from "../../assets/Calender.js";

import {
	BottomNav,
	NavLink,
	NavLinkAdd,
	BottomNavContainer,
} from "../../styles/BottomNavBar";

const StickyBottomAdminBar = () => {
	const page = useLocation().pathname.split("/")[3];

	return (
		<BottomNavContainer>
			{/* <!-- Bottom Nav Bar --> */}
			<BottomNav>
				<NavLink
					to={`/eventAdmin/admin/home`}
					id="home"
					selected={page === "home"}
				>
					{/* <!-- info SVG icon code --> */}
					<Home />
					<span className="nav__text" selected={page === "home"}>
						Home
					</span>
				</NavLink>

				{/* <NavLink
					to={`/eventAdmin/admin/students`}
					id="student"
					selected={page === "student"}
				>
					{/* <!-- info SVG icon code --> */}
				{/* <Student selected={page === "student"} />
					<span className="nav__text" selected={page === "student"}>
						Student
					</span>
				</NavLink> */}

				<NavLink
					to={`/eventAdmin/admin/programmes`}
					id="events"
					selected={page === "programmes"}
				>
					{/* <!-- info SVG icon code --> */}
					<Event />
					<span className="nav__text" selected={page === "programmes"}>
						Programmes
					</span>
				</NavLink>

				<NavLink
					to={`/eventAdmin/admin/program/create`}
					id="create"
					selected={page === "create"}
				>
					{/* <!-- info SVG icon code --> */}
					<AddSVG selected={page === "create"} />
					<div className="nav__text" selected={page === "create"}>
						Add Program
					</div>
				</NavLink>

				<NavLink
					to={`/eventAdmin/admin/orders`}
					id="orders"
					selected={page === "orders"}
				>
					{/* <!-- info SVG icon code --> */}
					<Order />
					<span className="nav__text" selected={page === "orders"}>
						Bookings
					</span>
				</NavLink>
				<NavLink
					to={`/eventAdmin/admin/checkin`}
					id="checkin"
					selected={page === "checkin"}
				>
					{/* <!-- info SVG icon code --> */}
					<Checkin />
					<span className="nav__text" selected={page === "checkin"}>
						Check-In
					</span>
				</NavLink>
			</BottomNav>
		</BottomNavContainer>
	);
};

export default StickyBottomAdminBar;
