import styled from "styled-components";

export const TopBarWrapper = styled.div`
	background-color: #1b2631;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px 0;
	width: 100%;
	height: 80px;
	// position: fixed;
	top: 0; /* Stick to the Top */
	z-index: 99999 !important;
	// box-shadow: 0px 0px 3px 5px rgba(0, 0, 0, 0.2);
	user-select: none; //Prevent text selection
	overflow: hidden;
	margin: auto;
	color: #ffffff;
	font-size: 20px;
	font0weight: 400;
	// border: 1px solid red;

	@media (max-width: 480px) {
		height: 50px;
		padding: 2px 0;
	}
`;

export const STickyTopBarWrapper = styled.div`
	background-color: #1b2631;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px 0;
	width: 100%;
	height: 80px;
	position: sticky;
	top: 0; /* Stick to the Top */
	z-index: 999 !important;
	// box-shadow: 0px 0px 3px 5px rgba(0, 0, 0, 0.2);
	user-select: none; //Prevent text selection
	// overflow: hidden;
	// margin: auto;
	color: #ffffff;
	font-size: 20px;
	font-weight: 400;
	// border: 1px solid red;

	@media (max-width: 480px) {
		height: 50px;
		padding: 2px 0;
	}
`;

export const TopNavBar = styled.nav`
	max-width: 978px; /* Default maximum width */
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 95%;

	& > * {
		flex: 1;
		// border: 1px solid red;
	}
`;

export const TopNavLeft = styled.div`
	svg {
		fill: #ffffff;
	}
`;

export const TopNavCenter = styled.div`
	text-align: right;
	display: flex, 
	justify-content: flex-end;
	align-items: center;
	margin: 0;
	flex: 2;
	// border: 1px solid red;

	h1 {
		display: block;
		// font-size: clamp(1rem, 3vw, 2.125rem);
		text-transform: uppercase;
		color: #eaf2f8;
		letter-spacing: 1px;
		font-family: serif;
		font-weight: 400;
		margin: 0;
		br {
			display: none;
		}
		@media (max-width: 480px) {
			font-size: 12px;
			br {
				display: none;
			}
		}
		span {
			margin-top: 0px;
			font-size: clamp(0.8rem, 1vw, 1rem);
			color: #eaf2f8;
			word-spacing: 1px;
			font-weight: normal;
			letter-spacing: 2px;
			text-transform: uppercase;
			font-family: sans-serif;
			font-weight: 500;
			display: grid;
			grid-template-columns: 1fr max-content 1fr;
			grid-template-rows: 20px 0;
			grid-gap: clamp(2px, 1vw, 10px); /* Adjust as needed */
			align-items: center;
			white-space: nowrap;
			@media (max-width: 480px) {
				font-size: 8px;
				letter-spacing: 0.5px;
			}
			&:after,
			&:before {
				content: " ";
				display: block;
				border-bottom: 1px solid #aeb6bf;
				border-top: 1px solid #aeb6bf;
				height: clamp(2px, 1vw, 1px); /* Adjust as needed */

				background-color: #1b2631;
			}
		}
	}
`;

export const TopNavRight = styled.div`
	font-size: clamp(10px, 2vw, 20px);
	color: #d5dbdb;
	font-family: serif;
	margin: 0;
	text-align: right;

	svg {
		fill: #ffffff;
	}
`;
