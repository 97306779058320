import React, { useState } from "react";
import styled from "styled-components";
import { Success, Fail } from "../../assets/Calender";
import { AddCourseForm } from "../forms/AddCourseForm";
import { CheckInModal } from "../checkin/CheckInModal";

// Styled checkbox container
const MessageContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20 0px;
`;

const IconContainer = styled.div`
	flex: 1;

	svg {
		width: 100px;
	}
`;

// Styled label for better accessibility and design
const TextContainer = styled.div`
	font-size: 14px;
	flex: 2;
`;

export const SuccessSubmission = ({ language }) => {
	return (
		<>
			<MessageContainer>
				<IconContainer>
					<Success />
				</IconContainer>

				{language === "en" ? (
					<TextContainer>It is successfully added/saved</TextContainer>
				) : (
					<TextContainer>
						感謝您的預訂！請檢查您的電子郵件以獲取預訂確認。
					</TextContainer>
				)}
			</MessageContainer>
		</>
	);
};

export const FailSubmission = ({ language }) => {
	return (
		<>
			<MessageContainer>
				<IconContainer>
					<Fail />
				</IconContainer>

				{language === "en" ? (
					<TextContainer>The booking failed. Please try again.</TextContainer>
				) : (
					<TextContainer>預訂失敗。請再試一次。</TextContainer>
				)}
			</MessageContainer>
		</>
	);
};

export const AddCourse = ({
	language,
	formData,
	setFormData,
	modalOpen,
	setModalOpen,
	handleSubmit,
	selectCourse,
}) => {
	return (
		<>
			<MessageContainer>
				<AddCourseForm
					formData={formData}
					setFormData={setFormData}
					handleSubmit={handleSubmit}
					selectCourse={selectCourse}
				/>
			</MessageContainer>
		</>
	);
};

export const ScannerCheckInPopUp = ({ data, handleSubmit }) => {
	return (
		<>
			<MessageContainer>
				<CheckInModal data={data} handleSubmit={handleSubmit} />
			</MessageContainer>
		</>
	);
};

export const Disclaimer = ({
	language,
	formData,
	setFormData,
	modalOpen,
	setModalOpen,
}) => {
	return (
		<>
			<MessageContainer>
				{/* <DisclaimerConsentCheckBox
					formData={formData}
					setFormData={setFormData}
					modalOpen={modalOpen}
					setModalOpen={setModalOpen}
				/> */}
			</MessageContainer>
			;
		</>
	);
};
