import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";

const Switch = styled.label`
	position: relative;
	display: inline-block;
	width: 60px;
	height: 34px;
`;

const Slider = styled.span`
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	transition: 0.4s;
	border-radius: 34px;

	&:before {
		position: absolute;
		content: "";
		height: 26px;
		width: 26px;
		left: 4px;
		bottom: 4px;
		background-color: white;
		transition: 0.4s;
		border-radius: 50%;
	}

	${Switch}:hover & {
		background-color: #b3b3b3;
	}
`;

const Input = styled.input`
	opacity: 0;
	width: 0;
	height: 0;

	&:checked + ${Slider} {
		background-color: #2196f3;
	}

	&:checked + ${Slider}:before {
		transform: translateX(26px);
	}

	&:focus + ${Slider} {
		box-shadow: 0 0 1px #2196f3;
	}
`;

function ToggleStatusButton({ initialStatus, handleToggle }) {
	const [status, setStatus] = useState(initialStatus);

	useEffect(() => {
		setStatus(initialStatus);
	}, [initialStatus]);

	const toggleStatus = () => {
		setStatus((currentStatus) => (currentStatus === 1 ? 0 : 1));
		handleToggle();
	};

	console.log(status);
	return (
		<Switch>
			<Input type="checkbox" checked={status === 1} onChange={toggleStatus} />
			<Slider></Slider>
		</Switch>
	);
}

export default ToggleStatusButton;
