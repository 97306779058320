import React from "react";
import {
	TopBarWrapper,
	TopNavBar,
	TopNavLeft,
	TopNavCenter,
	TopNavRight,
} from "./TopbarStyles.jsx";

import logo from "../../assets/DMGdigi-logo.png";

import { LogoContainer, Logo } from "../../styles/SignUp";

import { useContext } from "react";
import { AuthContext } from "../../context/authContext";
import NavSidebar from "../navSidebar/NavSidebar.jsx";
import { Link } from "react-router-dom";
import { NotificationsNone, Language, Settings } from "@material-ui/icons";

export default function Topbar() {
	const { currentUser, logout } = useContext(AuthContext);
	const currentDate = new Date().toLocaleDateString("en-US", {
		weekday: "long",
		day: "numeric",
		month: "long",
		year: "numeric",
	});
	return (
		<TopBarWrapper>
			<TopNavBar>
				<TopNavLeft>{/* <NavSidebar /> */}</TopNavLeft>
				<TopNavCenter>
					<LogoContainer>
						<Logo src={logo} alt="Logo" />
					</LogoContainer>
				</TopNavCenter>
				<TopNavRight>
					<span>{currentUser?.username}</span>
					{
						currentUser && (
							<>
								<span onClick={logout}>Logout</span>
							</>
						)
						//  : (
						// 	<Link className="link" to="/login">
						// 		Login
						// 	</Link>
					}
				</TopNavRight>
			</TopNavBar>
		</TopBarWrapper>
	);
}
