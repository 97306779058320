import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import {
	ContentContainer,
	EventWrapper,
	EventTextWrapper,
	EventTitle,
	EventDate,
	EventPicWrapper,
	EventPic,
	TableContainer,
	Table,
	TableHeader,
	TableRow,
	TableCell,
	ProgramCell,
	PendingStatus,
	ConfirmedStatus,
} from "../../styles/Admin";

const Orders = ({ data }) => {
	const [orders, setOrders] = useState(data);

	useEffect(() => {
		setOrders(data);
	}, [data]);

	return (
		<>
			<ContentContainer>
				<TableContainer>
					<Table>
						<thead>
							<TableRow>
								<TableHeader>Status</TableHeader>
								<TableHeader>Order #</TableHeader>
								<TableHeader>Contact</TableHeader>
								<TableHeader>Ordered Item</TableHeader>
								<TableHeader>Name of Student</TableHeader>
								<TableHeader>Date of Order</TableHeader>
								{/* Add other headers here */}
							</TableRow>
						</thead>
						<tbody>
							{orders?.map((order) => {
								const orderDetails = order.order_details;
								const studentDetails = order.participant_info;

								console.log(orderDetails);
								return (
									<TableRow key={order.id}>
										<TableCell>
											{order.status === 1 ? (
												<ConfirmedStatus>Confirmed</ConfirmedStatus>
											) : (
												order.status === 0 && (
													<PendingStatus>Pending</PendingStatus>
												)
											)}
										</TableCell>
										<TableCell>
											<Link to={`/eventAdmin/admin/order/id/${order.id}`}>
												{order.id}
											</Link>
										</TableCell>
										<TableCell>
											<ProgramCell>
												<p>{order.user_name}</p>
												<p>{order.contact}</p>
												<p>{order.email}</p>
											</ProgramCell>
										</TableCell>
										<TableCell>
											<ProgramCell>
												<p>{orderDetails.courseCode}</p>
												<p>{orderDetails.programName}</p>
												<p>{orderDetails.option1value}</p>
												<p>{orderDetails.option2value}</p>
											</ProgramCell>
										</TableCell>
										<TableCell>
											<ProgramCell>
												<p>
													{studentDetails.firstname} {studentDetails.lastname}
												</p>

												<p>{studentDetails.gender}</p>
												<p>{studentDetails.dob}</p>
											</ProgramCell>
										</TableCell>
										<TableCell>
											{new Date(order.order_date).toLocaleString()}
										</TableCell>
										{/* Add other cells here */}
									</TableRow>
								);
							})}
						</tbody>
					</Table>
				</TableContainer>
			</ContentContainer>
		</>
	);
};

export default Orders;
