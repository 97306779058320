import React, { useState, useEffect } from "react";

import styled from "styled-components";

const ProgressBarContainer = styled.div`
	width: 100%;
	height: 20px;
	background-color: #f3f3f3;
	border-radius: 5px;
	position: relative;
`;

const ProgressFill = styled.div`
	height: 100%;
	background-color: #009578;
	border-radius: 5px;
	width: ${(props) => `${props.percentage}%`};
`;

const ProgressLabel = styled.div`
	position: absolute;
	top: 0;
	width: 100%;
	text-align: center;
	line-height: 20px; // Match the height of the progress bar
`;

const AttendanceBar = ({ totalCapacity, registered }) => {
	const [progress, setProgress] = useState(0);

	useEffect(() => {
		// Calculate percentage of registered attendees
		const percentage = (registered / totalCapacity) * 100;
		setProgress(percentage);
	}, [registered, totalCapacity]);

	const remaining = totalCapacity - registered;

	return (
		<>
			<h3>
				Attendance: {registered} / {totalCapacity}
			</h3>
			<ProgressBarContainer>
				<ProgressFill percentage={progress} />
				<ProgressLabel>{`${registered} Registered, ${remaining} Remaining`}</ProgressLabel>
			</ProgressBarContainer>
		</>
	);
};

export default AttendanceBar;
