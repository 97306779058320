import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import {
	ContentContainer,
	EventWrapper,
	EventTextWrapper,
	EventTitle,
	EventDate,
	EventPicWrapper,
	EventPic,
} from "../../styles/Admin";

const StyledLink = styled(Link)`
	text-decoration: none;
	color: #000000;
	width: 100%;

	&:visited {
		color: #000000;
	}
`;

const Events = ({ data }) => {
	console.log(data);

	return (
		<ContentContainer>
			{data?.map((program) => (
				<StyledLink
					to={`/eventAdmin/admin/program/${program.id}`}
					key={program.id}
				>
					<EventWrapper key={program.id}>
						<EventTextWrapper>
							<EventTitle>{program.name}</EventTitle>
							{/* <EventDate>{event.date}</EventDate> */}
						</EventTextWrapper>
						<EventPicWrapper>
							<EventPic
								src={process.env.PUBLIC_URL + program.image}
								alt={program.name}
							/>
						</EventPicWrapper>
					</EventWrapper>
				</StyledLink>
			))}
		</ContentContainer>
	);
};

export default Events;
