import React, { useState, useEffect, useContext } from "react";
import { GlobalStyles, BodyContainer2, FixedContainer } 
from "./styles/GlobalStyles";

import './axiosConfig';

import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  Navigate,
} from "react-router-dom";

// import WindowSizeApp from "./tools/WindowSizeApp";
import Topbar from "./components/topbar/Topbar";
import {TopbarInsideModule} from "./components/topbar/TopbarInsideModule";

import StickyBottomAdminBar from "./components/stickyBottomActionBar/stickyBottomAdminBar";

//REGISTER
import SignUp from "./pages/SignUp/SignUp";
//LOGIN
import Login from "./pages/Login/Login";
//Verify
import Verify from "./pages/Verify/Verify";

//**Admin **/
import Overview from "./pages/Admin/Overview";
import CreateProgram from "./pages/Admin/CreateProgram";
import Program from "./pages/Admin/ProgramOverview";
import ProgramCourses from "./pages/Admin/ProgramCourses";
import EditProgram from "./pages/Admin/EditProgram";
import Events from "./pages/Admin/Programmes";
import Event from "./pages/Admin/Event";
import OrdersPage from "./pages/Admin/Orders";
import OrdersCourses from "./pages/Admin/OrdersCourses";
import Students from "./pages/Admin/Students";
import Order from "./pages/Admin/Order";
import QRScanner from "./pages/Admin/QRScanner";
import Checkin from "./pages/Admin/Checkin";

import CourseCheckIn from "./pages/Admin/CourseCheckIn";



import CourseBookingDetails from "./pages/Admin/CourseBookingDetails";

import { AuthContext } from "./context/authContext";


import 'react-datepicker/dist/react-datepicker.css';


import { useLocation } from 'react-router-dom';




const DynamicallyChangeHeightContainer = () => {
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return viewportHeight;
};


const UnauthenticatedRoute = ({ children, redirectPath = '/eventAdmin/users/login' }) => {
  const { currentUser, loading } = useContext(AuthContext);

  if (loading) {
    return <div>Loading...</div>;  // Or your loading component
  }

   return currentUser ? children : <Navigate to={redirectPath} />;
};


const App = () => {







const { currentUser, refreshUser } = useContext(AuthContext);
  const viewportHeight = DynamicallyChangeHeightContainer();  // This will give the dynamic viewport height



// disable the default zoom in  when focus on the input field on mobile device


  const Layout = () => {




    return (
      <>
        <GlobalStyles />
             <FixedContainer height={viewportHeight}>
        <Topbar />
       <BodyContainer2>
        <Outlet />
       </BodyContainer2>
        <StickyBottomAdminBar />
        </FixedContainer>
      </>
    ) 
  };

  const LayoutNew = () => {
return (
      <>
        <GlobalStyles />
        <FixedContainer>
        <Topbar />
        <Outlet />
            <StickyBottomAdminBar />
        </FixedContainer>
      </>
    )
  };
  //STEP 2. Create ProtectedRoute function:
  //Can pass all pages&layouts through child component
  const ProtectedRoute = ({ children }) => {
    //If there's NO current user logged in, then navigate to login page
    // if (!currentUser) {
    //   return <Navigate to="/login" />;
    // }
    //If user is logged in, show the children which is a protected 'Layout'
const { currentUser, loading} = useContext(AuthContext);

if (loading) {
  return <>loading </>; // Replace this with your actual loading spinner component
}
  return currentUser ? children : <Navigate to="/eventAdmin/users/login" />;
  };


  
  const router = createBrowserRouter([
    {
      // basename: "/eventAdmin",
      // path:"/",

      //Step 4. Wrap the <Layout /> with the <ProttectedRoute>
      element: (
        <ProtectedRoute>
          <Layout />
        </ProtectedRoute>
      ),

      children: [
        {
          //PART 0 Temporary Home Page. 1st NPM Start goes here!
          path: "/eventAdmin/admin/home",
          element: <Overview />,
        },

         {
          //PART 1 All events
          path: "/eventAdmin/admin/programmes",
          element: <Events />,
        },
          {
          //PART 1 All events
          path: "/eventAdmin/admin/orders",
          element: <OrdersPage />,
        },
          {
          //PART 1 All events
          path: "/eventAdmin/admin/orders/courses",
          element: <OrdersCourses />,
        },
         {
          //PART 1 All events
          path: "/eventAdmin/admin/students",
          element: <Students />,
        },
       
           
     
      ]
    },
    //Login
    {
      path: "/eventAdmin/users/login",
      element: <>   <GlobalStyles /><Login /></>,
    },
    {
      //Register
      path: "/eventAdmin/users/sign_up",
      element: <>   <GlobalStyles />
      <SignUp /></>,
    },
      {
      //Verify
      path: "/eventAdmin/users/sign_up/verify/:token",
      element: <>   <GlobalStyles />
      <Verify /></>,
    },
 {
      //Create Event
      path: "/eventAdmin/admin/program/create",
      element:  <UnauthenticatedRoute>  <GlobalStyles />
 <FixedContainer height={viewportHeight}>
    			
       <CreateProgram/>

        </FixedContainer>
         </UnauthenticatedRoute>
 ,
    },

     {
      //Get Shop Order by ID
      path: "/eventAdmin/admin/order/id/:id",
      element:  <UnauthenticatedRoute>  <GlobalStyles />
 			<Order />
         </UnauthenticatedRoute>
    },

      {
      //Get Shop Order by token
      path: "/eventAdmin/admin/order/token/:token",
      element:  <UnauthenticatedRoute>  <GlobalStyles />
 			<Order />
         </UnauthenticatedRoute>
    },

     {
      //Get Program
      path: "/eventAdmin/admin/program/:id",
      element:  <UnauthenticatedRoute>  <GlobalStyles />
 <FixedContainer height={viewportHeight}>
    		
       <Program/>
   
        </FixedContainer>
         </UnauthenticatedRoute>
 ,
    },
    {
      //Get Program Course
      path: "/eventAdmin/admin/program/:id/courses",
      element:  <UnauthenticatedRoute>  <GlobalStyles />
 <FixedContainer height={viewportHeight}>
    		
       <ProgramCourses/>
   
        </FixedContainer>
         </UnauthenticatedRoute>
 ,
    },

      {
      //Edit Program
      path: "/eventAdmin/admin/program/:id/edit",
      element:  <UnauthenticatedRoute>  <GlobalStyles />
 <FixedContainer height={viewportHeight}>
    		
       <EditProgram/>
   
        </FixedContainer>
         </UnauthenticatedRoute>
 ,
    },

      {
      //Get Program Course
      path: "/eventAdmin/admin/orders/courses/:id/checkin",
      element:  <UnauthenticatedRoute>  <GlobalStyles />
 <FixedContainer height={viewportHeight}>
    		
       <CourseCheckIn/>
   
        </FixedContainer>
         </UnauthenticatedRoute>
    },

      {
      //Get Program Course
      path: "/eventAdmin/admin/orders/courses/:id",
      element:  <UnauthenticatedRoute>  <GlobalStyles />
 <FixedContainer height={viewportHeight}>
    		
       <CourseBookingDetails/>
   
        </FixedContainer>
         </UnauthenticatedRoute>
    },
    
  {
      //Get Program Course
        path: "/eventAdmin/admin/checkin",
      element:  <UnauthenticatedRoute>  <GlobalStyles />
 <FixedContainer height={viewportHeight}>
    		
       <Checkin/>
   
        </FixedContainer>
         </UnauthenticatedRoute>
    },

       {
          //PART 1 All events
          path: "/eventAdmin/admin/checkin/scanner",
           element:  <UnauthenticatedRoute>  <GlobalStyles />
            <FixedContainer height={viewportHeight}>
 <QRScanner />
            </FixedContainer>
               </UnauthenticatedRoute>
        },

       {
          //PART 1 All events
        
        
        },
// {
//       //All Orders
//       path: "/eventAdmin/admin/orders",
//       element:  <UnauthenticatedRoute>  <GlobalStyles />
//  <FixedContainer height={viewportHeight}>
    	
// <Orders />

//         </FixedContainer>
//          </UnauthenticatedRoute>
//  ,
//     },

  ]);

      


  return <RouterProvider router={router} />;
};
export default App;
