import React, { useRef, useState, useEffect } from "react";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import { TopbarInsideModule } from "../../components/topbar/TopbarInsideModule.jsx";
import CourseClassSelection from "../../components/checkin/CourseClassSelection.jsx";

import CheckinCourses from "../../components/checkin/CheckinCourses.jsx";

import StickyTopTab from "../../components/stickyTopTab/StickyTopTab.jsx";

import axios from "axios";

import { BodyContainer2 } from "../../styles/GlobalStyles.jsx";

import { SubmitButton } from "../../styles/SignUp.js";

import {
	ContentContainer,
	ClassInfoWrapper,
	ClassTitle,
	ClassDetailsStyled,
	DetailValue,
	Label,
	OptionListPrice,
	Subheader,
	EventWrapper,
	EventTextWrapper,
	EventTitle,
	EventDate,
	EventPicWrapper,
	EventPic,
	TableContainer,
	Table,
	TableHeader,
	TableRow,
	TableCell,
	ProgramCell,
	PendingStatus,
	ConfirmedStatus,
	FixedBottomContainer,
} from "../../styles/Admin";

import { LoadingOverlay, LoadingSpinner } from "../../styles/GlobalStyles.jsx";

import StickyBottomCourseBar from "../../components/stickyBottomActionBar/stickyBottomCourseBar";

import MessageModal from "../../components/modal/Modal.jsx";

const Checkin = ({ students }) => {
	const [data, setData] = useState([]);
	const [courses, setCourses] = useState([]);
	const [selectedProgram, setSelectedProgram] = useState({});
	const [filterCriteria, setFilterCriteria] = useState("program_id");
	const [filter, setFilter] = useState("");
	const [sortField, setSortField] = useState("");
	const [sortOrder, setSortOrder] = useState("ASC");

	const navigate = useNavigate();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await axios.get(`/eventAdmin/api/class/getProgrammes`);

				setData(res.data);
				setFilter(res.data[0].id);
			} catch (error) {
				if (error.response.status === 401 || error.response.status === 403) {
					navigate(`/eventAdmin/users/login`);
				} else {
					console.error("Error updating status:", error);
				}
			}
		};

		fetchData();
	}, []);

	console.log(selectedProgram);

	useEffect(() => {
		console.log("fetching courses data");
		const fetchCoursesData = async () => {
			try {
				const res = await axios.get(
					`/eventAdmin/api/orders/courses?filter=${filterCriteria}&value=${filter}&sort=${sortField}&order=${sortOrder}`
				);

				setCourses(res.data);
			} catch (err) {
				console.log(err);
			}
		};

		fetchCoursesData();
	}, [selectedProgram]);

	console.log(courses);

	return (
		<>
			<TopbarInsideModule
				location={`Check-in Panel`}
				goBack={true}
				link={`/eventAdmin/admin/home`}
				icon="scanner"
			/>
			<BodyContainer2>
				<ContentContainer>
					<ClassInfoWrapper>
						<Subheader>Select Program</Subheader>
						<CourseClassSelection
							data={data}
							selectedProgram={selectedProgram}
							setSelectedProgram={setSelectedProgram}
						/>
					</ClassInfoWrapper>

					<ClassInfoWrapper>
						<Subheader>List of Courses</Subheader>
						<ClassTitle>{selectedProgram?.name}</ClassTitle>
						{selectedProgram && courses.length > 0 && (
							<CheckinCourses
								data={courses && courses.length > 0 ? courses : []}
								checkin={true}
							/>
						)}
					</ClassInfoWrapper>
				</ContentContainer>
			</BodyContainer2>

			{/* <Overview
				data={data}
				handleToggle={handleToggle}
				handleDelete={handleDelete}
			/> */}
			{/* <SearchSortBar /> */}
			{/* // <ProgramChangingContent tab={currentTab} data={data} /> */}

			{/* {modalOpen && (
				<MessageModal
					isOpen={modalOpen}
					template={modalTemplate}
					language={"en"}
					autoClose={autoClose}
					setModalOpen={setModalOpen}
					formData={formData}
					setFormData={setFormData}
					handleSubmit={handleSubmit}
					selectCourse={selectCourse}
				/>
			)} */}
		</>

		// 	{/***************************************
		//     // LOADING CIRCLE //
		// ***************************************/}
		// 	{loadingCircle && (
		// 		<LoadingOverlay>
		// 			<LoadingSpinner />
		// 		</LoadingOverlay>
		// 	)}
		// // </>
	);
};

export default Checkin;
