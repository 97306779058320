//source: https://reactsvgicons.com/search?q=filtering
import * as React from "react";

function BackArrow(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14.37 17.17"
      height="1em"
      width="1em"
      {...props}
    >
      <defs>
        <style>
        </style>
      </defs>
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="M.71,7.83L13.66,1.16v1.93L2.75,8.56v.06l10.91,5.47v1.93L.71,9.34v-1.51Z"
        />
      </g>
    </svg>
  );
}

export default BackArrow;
