import React, { useState, useEffect, useRef } from "react";
import AttendanceBar from "./AttendenceBar.jsx";
import styled from "styled-components";

import {
	Container,
	TopTitle,
	TopTitleNameColor,
	ClickBackArrowHolder,
	Top,
	BodyContainer,
	ErrorMessageContainer,
} from "../../styles/GlobalStyles";

// Define your styled components
const StickyDiv = styled.div`
	position: sticky;
	// border: 1px solid red;
	top: 80px;
	height: 60vh;
	// overflow-y: hidden;
	// overflow-x: auto;
	margin: auto;
	z-index: 9999;
	background-color: transparent;
	width: 100%;
`;

const ScrollableDiv = styled.div`
	height: 100%; /* Or set a specific height */
	max-height: 75vh;
	overflow-x: auto;
	overflow-y: auto;
	// border: 10px solid yellow;
`;

const Table = styled.table`
	width: max-content;
	border-collapse: collapse;
	white-space: nowrap; /* Prevents cells from wrapping text */
	// overflow-y: scroll;
	// overflow-x: auto;
`;

const TableHeader = styled.th`
	background-color: #f3f3f3;
	text-align: left;
	padding: 8px;
	cursor: pointer;
	position: sticky;
	top: 0;
	z-index: 2;
`;

const THead = styled.thead`
	display: table;
	width: 100%;
`;

const TBody = styled.tbody`
	display: block;
	width: 100%;
	max-height: 50vh; /* adjust as needed */
	overflow: auto;
`;

const TableRow = styled.tr`
	width: 100%;
	display: table;
	overflow-x: auto;
	&:nth-child(even) {
		background-color: #f2f2f2;
	}
`;

const TableCell = styled.td`
	padding: 8px;
	text-align: left;
`;

const Registration = () => {
	const [registered, setRegistered] = useState(80);

	const [totalCapacity, setTotalCapacity] = useState(100);

	const [data, setData] = useState([
		{
			status: 1,
			Id: 1,
			title: "Mr",
			lastname: "Doe",
			firstname: "John",
			teeoffhole: "Hole 1",
		},
		{
			status: 1,
			Id: 2,
			title: "Mr",
			lastname: "Smith",
			firstname: "James",
			teeoffhole: "Hole 2",
		},
		{
			status: 1,
			Id: 3,
			title: "Mr",
			lastname: "Smith",
			firstname: "James",
			teeoffhole: "Hole 2",
		},
		{
			status: 1,
			Id: 4,
			title: "Mr",
			lastname: "Smith",
			firstname: "James",
			teeoffhole: "Hole 2",
		},
		{
			status: 1,
			Id: 5,
			title: "Mr",
			lastname: "Smith",
			firstname: "James",
			teeoffhole: "Hole 2",
		},
		{
			status: 1,
			Id: 6,
			title: "Mr",
			lastname: "Smith",
			firstname: "James",
			teeoffhole: "Hole 2",
		},
		{
			status: 1,
			Id: 7,
			title: "Mr",
			lastname: "Smith",
			firstname: "James",
			teeoffhole: "Hole 2",
		},

		{
			status: 1,
			Id: 8,
			title: "Mr",
			lastname: "Smith",
			firstname: "James",
			teeoffhole: "Hole 2",
		},

		{
			status: 1,
			Id: 9,
			title: "Mr",
			lastname: "Smith",
			firstname: "James",
			teeoffhole: "Hole 2",
		},

		{
			status: 1,
			Id: 10,
			title: "Mr",
			lastname: "Smith",
			firstname: "James",
			teeoffhole: "Hole 2",
		},

		{
			status: 1,
			Id: 11,
			title: "Mr",
			lastname: "Smith",
			firstname: "James",
			teeoffhole: "Hole 2",
		},

		{
			status: 1,
			Id: 12,
			title: "Mr",
			lastname: "Smith",
			firstname: "James",
			teeoffhole: "Hole 2",
		},

		{
			status: 1,
			Id: 13,
			title: "Mr",
			lastname: "Smith",
			firstname: "James",
			teeoffhole: "Hole 2",
		},

		{
			status: 1,
			Id: 14,
			title: "Mr",
			lastname: "Smith",
			firstname: "James",
			teeoffhole: "Hole 2",
		},

		{
			status: 1,
			Id: 15,
			title: "Mr",
			lastname: "Smith",
			firstname: "James",
			teeoffhole: "Hole 2",
		},

		{
			status: 1,
			Id: 16,
			title: "Mr",
			lastname: "Smith",
			firstname: "James",
			teeoffhole: "Hole 2",
		},

		{
			status: 1,
			Id: 17,
			title: "Mr",
			lastname: "Smith",
			firstname: "James",
			teeoffhole: "Hole 2",
		},

		{
			status: 1,
			Id: 18,
			title: "Mr",
			lastname: "Smith",
			firstname: "James",
			teeoffhole: "Hole 2",
		},

		{
			status: 1,
			Id: 19,
			title: "Mr",
			lastname: "Smith",
			firstname: "James",
			teeoffhole: "Hole 2",
		},
		{
			status: 1,
			Id: 20,
			title: "Mr",
			lastname: "Smith",
			firstname: "James",
			teeoffhole: "Hole 2",
		},

		{
			status: 1,
			Id: 21,
			title: "Mr",
			lastname: "Smith",
			firstname: "James",
			teeoffhole: "Hole 2",
		},

		{
			status: 1,
			Id: 22,
			title: "Mr",
			lastname: "Smith",
			firstname: "James",
			teeoffhole: "Hole 2",
		},

		{
			status: 1,
			Id: 23,
			title: "Mr",
			lastname: "Smith",
			firstname: "James",
			teeoffhole: "Hole 2",
		},

		{
			status: 1,
			Id: 24,
			title: "Mr",
			lastname: "Smith",
			firstname: "James",
			teeoffhole: "Hole 2",
		},
	]);

	const handleClick = (e) => {
		console.log("selectdata");
	};

	return (
		<>
			<AttendanceBar totalCapacity={totalCapacity} registered={registered} />
			<StickyDiv>
				<TopTitle>All Guests</TopTitle>

				<ScrollableDiv>
					<Table>
						<THead>
							<TableRow>
								{Object.keys(data[0]).map((key) => (
									<TableHeader key={key}>{key}</TableHeader>
								))}
							</TableRow>
						</THead>
						<TBody>
							{data.map((row, index) => (
								<TableRow key={index} onClick={handleClick}>
									{Object.values(row).map((cell, index) => (
										<TableCell key={index}>{cell}</TableCell>
									))}
								</TableRow>
							))}
						</TBody>
					</Table>
				</ScrollableDiv>
			</StickyDiv>
		</>
	);
};

export default Registration;
