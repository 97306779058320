import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";

import StickyTopTab from "../../components/stickyTopTab/StickyTopTab.jsx";
import ProgrammesChangingContent from "../../components/programmes/ProgrammesChangingContent.jsx";

import { TabContext } from "../../registrationContext.js";

const Programmes = () => {
	const [data, setData] = useState([]);
	const [filteredData, setfilteredData] = useState([]);
	// const cat = useLocation().search;
	const [filterCriteria, setFilterCriteria] = useState("");
	const [filter, setFilter] = useState("");
	const [resultChange, setResultChange] = useState("All");
	// const navigate = useNavigate();
	const [currentTab, setCurrentTab] = useState("Programmes");
	const [tabValues, setTabValues] = useState([
		{
			name: "tab_1",
			label: "Programmes",
			link: "/eventAdmin/admin/programmes",
		},
		// {
		// 	name: "tab_2",
		// 	label: "Courses",
		// },
	]);

	const location = useLocation();

	// Set currentTab based on the current URL path
	useEffect(() => {
		const matchingTab = tabValues?.find(
			(tab) => tab.link === location.pathname
		);
		if (matchingTab) {
			setCurrentTab(matchingTab.label);
		}
	}, [location.pathname, tabValues]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await axios.get(
					`/eventAdmin/api/class/getProgrammes?filter=${filterCriteria}&value=${filter}`
				);

				// const modifiedData = res.data.map((event) => {
				// 	const datetimeArray = JSON.parse(event.datetime);

				// 	datetimeArray.forEach((element) => {
				// 		const date = element.date.substring(0, 10);
				// 		return { ...element, date: date };
				// 	});
				// });
				setData(res.data);
			} catch (err) {
				console.log(err);
			}
		};

		fetchData();
		// setResultChange("All");

		// const intervalId = setInterval(fetchData, 1000);

		// Clean up the interval when the component unmounts or when dependencies change
		// return () => {
		// 	clearInterval(intervalId);
		// };
	}, [filterCriteria, filter]);

	// useEffect(() => {
	// 	const fetchData = async () => {
	// 		try {
	// 			const res = await axios.get(
	// 				`/eventAdmin/api/event?filter=${filterCriteria}&value=${filter}`
	// 			);

	// 			let uniqueArray = [...new Set(res.data.map((item) => item.category))]; // Remove duplicates

	// 			let newtabValues = [
	// 				{ name: "tab_1", label: "All Events" },
	// 				...uniqueArray.map((item, index) => ({
	// 					name: `tab_${index + 2}`, // Start from tab_2
	// 					label: item,
	// 				})),
	// 			];
	// 			setTabValues(newtabValues);
	// 		} catch (err) {
	// 			console.log(err);
	// 		}
	// 	};

	// 	fetchData();
	// 	// setResultChange("All");

	// 	// const intervalId = setInterval(fetchData, 1000);

	// 	// Clean up the interval when the component unmounts or when dependencies change
	// 	// return () => {
	// 	// 	clearInterval(intervalId);
	// 	// };
	// }, []);

	const handleFilterChange = (name) => {
		console.log(name);
		// setCurrentTab(name);
	};

	return (
		<TabContext.Consumer>
			{(tab) => (
				<>
					{tabValues ? (
						<StickyTopTab
							tabValues={tabValues}
							tabValue={currentTab}
							changeTabAction={handleFilterChange}
							currentTab={currentTab}
							setCurrentTab={setCurrentTab}
						/>
					) : null}
					<ProgrammesChangingContent tab={currentTab} data={data} />
				</>
			)}
		</TabContext.Consumer>
	);
};

export default Programmes;
