// path: client/src/components/TopSlider.js
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, EffectFade } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import styled from "styled-components";

// const images = [
//   "/images/2.jpg",
//   "/images/3.jpg",
//   "/images/4.jpg",
//   "/images/5.jpg",
// ];

const SliderContainer = styled.div`
	width: 100%;
	position: relative;

	.swiper-slide img {
		width: 100%;
		height: auto;
	}

	.swiper-pagination {
		position: absolute;
		bottom: 24px;
		left: 0;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 10; /* Ensure pagination is above the image */
	}

	.swiper-pagination-bullet {
		background: #eee;
		width: 15px;
		height: 15px;
		opacity: 1;
		margin: 0 10px;
	}

	.swiper-pagination-bullet-active {
		background: #ddd;
		width: 15px;
		height: 15px;
	}
`;

const TopSlider = ({ images }) => {
	return (
		<SliderContainer>
			<Swiper
				modules={[Pagination, Autoplay, EffectFade]}
				pagination={{ clickable: true }}
				autoplay={{ delay: 2800 }}
				loop={true}
				effect="fade"
				spaceBetween={50}
				slidesPerView={1}
			>
				{images.map((image, index) => {
					return (
						<SwiperSlide key={index}>
							<img
								src={process.env.PUBLIC_URL + image}
								alt={`Slide ${index + 1}`}
							/>
						</SwiperSlide>
					);
				})}
			</Swiper>
		</SliderContainer>
	);
};

export default TopSlider;
