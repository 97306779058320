import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Cookies from "js-cookie";

// Styled components
const Container = styled.div`
	padding: 20px;
	background-color: #f4f4f4;
	font-size: 14px;
	width: 100%;
`;

const Dropdown = styled.select`
	width: 100%;
	padding: 2vw;
	margin-bottom: 2vw;
	border: 1px solid #ccc;
	border-radius: 5px;
	-webkit-appearance: none; /* Removes default browser styling */
	background-image: url('data:image/svg+xml;utf8,<svg fill="black" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>'); /* Adds custom dropdown arrow */
	background-repeat: no-repeat;
	background-position: right 1rem center; /* Positions the custom dropdown arrow */
`;

const Grid = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
	gap: 5px;
`;

const TimeslotButton = styled.button`
	position: relative;
	padding: 10px;
	border: ${(props) =>
		props.selected
			? "3px solid rgba(255, 133, 89, 1)"
			: "2px solid rgba(0, 0, 0, 0.1)"};
	background-color: ${(props) =>
		props.selected ? "rgba(255, 133, 89, 0.3)" : "#ffffff"};
	color: rgba(0, 0, 0, 1);
	border-radius: 5px;
	cursor: ${(props) => (props.available ? "pointer" : "default")};
	&:hover {
		background-color: ${(props) =>
			props.selected ? "rgba(255, 133, 89, 0.3)" : "#ffffff"};
	}

	&.full {
		background-color: lightgrey;
	}

	&.full::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(to top right, transparent 50%, grey 50%);
		opacity: 0.5;
		box-sizing: border-box;
	}
`;

const Remark = styled.div`
	font-size: 10px;
	color: red;
`;

const CourseClassSelection = ({
	setSelectedProgram,
	selectedProgram,
	data,
}) => {
	useEffect(() => {
		if (data.length === 1) {
			setSelectedProgram(data[0]);
		}
	}, [data]);

	return (
		<Container>
			{/* <h2>Book Your Workshop</h2> */}
			<Dropdown
				value={selectedProgram.id}
				onChange={(e) => setSelectedProgram(e.target.value)}
			>
				<option value="">Select a Program</option>
				{data.map((program, index) => (
					<option key={index} value={program.id}>
						{program.name}
					</option>
				))}
			</Dropdown>
		</Container>
	);
};

export default CourseClassSelection;
