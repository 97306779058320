import React from "react";
import {
	ContentContainer,
	EventWrapper,
	EventTextWrapper,
	EventTitle,
	EventDate,
	EventPicWrapper,
	EventPic,
	TableContainer,
	Table,
	TableHeader,
	TableRow,
	TableCell,
	ProgramCell,
	PendingStatus,
	ConfirmedStatus,
	FixedBottomContainer,
} from "../../styles/Admin";
import { Link, useNavigate } from "react-router-dom";

const CheckinCourses = ({ data, selectCourse, setSelectCourse, checkin }) => {
	const navigate = useNavigate();
	return (
		<ContentContainer>
			<TableContainer>
				<Table>
					<thead>
						<TableRow>
							<TableHeader>Status</TableHeader>
							<TableHeader>Enrolment Status</TableHeader>
							<TableHeader>Checkin Status</TableHeader>
							<TableHeader>Course code</TableHeader>
							<TableHeader>Option 1</TableHeader>
							<TableHeader>Option 2</TableHeader>

							{/* Add other headers here */}
						</TableRow>
					</thead>
					<tbody>
						{data?.map((course) => (
							<TableRow
								key={course.course_id}
								onClick={() => {
									navigate(
										`/eventAdmin/admin/orders/courses/${course.course_id}/checkin`
									);
								}}
								style={
									selectCourse === course
										? { backgroundColor: "lightgray" }
										: {}
								}
							>
								<TableCell>
									{course.course_open ? (
										<ConfirmedStatus>Open</ConfirmedStatus>
									) : (
										<PendingStatus>Closed</PendingStatus>
									)}
								</TableCell>
								<TableCell>
									{course.actual_enrollment}/{course.course_capacity}
									{course?.actual_enrollment === course?.course_capacity && (
										<div style={{ color: "red" }}>Full</div>
									)}
								</TableCell>
								<TableCell>{course.course_code}</TableCell>
								<TableCell>{course.course_option1value}</TableCell>
								<TableCell>{course.course_option2value}</TableCell>
								{/* Add other cells here */}
							</TableRow>
						))}
					</tbody>
				</Table>
			</TableContainer>
		</ContentContainer>
	);
};

export default CheckinCourses;
