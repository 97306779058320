import React, { useRef, useState, useEffect } from "react";

import { BodyContainer2 } from "../../styles/GlobalStyles.jsx";

import Programmes from "./Programmes.jsx";

import Courses from "./Courses.jsx";

const ProgrammesChangingContent = ({ tab, handleTab, data }) => {
	// Now you can use tab and handleTab here
	switch (tab) {
		case "Programmes":
			return (
				<BodyContainer2>
					<Programmes data={data} />
				</BodyContainer2>
			);
		case "Courses":
			return (
				<BodyContainer2>
					{/* Bag Drop related content */}
					<Courses data={data} />
				</BodyContainer2>
			);

		// case "Classes":
		// 	return (
		// 		<BodyContainer2>
		// 			{/* Premiums related content */}

		// 			<Classes data={data} />
		// 		</BodyContainer2>
		// 	);

		default:
	}
};
export default ProgrammesChangingContent;
