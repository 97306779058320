import React, { useState, useEffect, createRef, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
// import "./register.scss";
import logo from "../../assets/DMGdigi-logo.png";

import {
	Body,
	ContentContainer,
	LogoContainer,
	Logo,
	TitleContainer,
	FormContainer,
	InputContainer,
	InputHorizontalContainer,
	Input,
	CodeInput,
	SubmitButton,
	FlexWrapper,
	DisclaimerWrapper,
	FormFeedback,
} from "../../styles/SignUp";

import { AuthContext } from "../../context/authContext";

const Verify = () => {
	const { refreshUser } = useContext(AuthContext);
	const location = useLocation();
	const token = location.pathname.split("/")[5];
	const navigate = useNavigate();

	const [code, setCode] = useState(Array(6).fill("")); //Initialize an array of 6 empty strings

	const inputsRef = Array(6)
		.fill()
		.map(() => createRef()); // Create refs for your inputs
	const [formErrors, setFormErrors] = useState({});

	const handleChange = (index) => (e) => {
		let newCode = [...code];
		newCode[index] = e.target.value;
		setCode(newCode);

		// If the new value is not empty and there is an input field next, focus it
		if (e.target.value && inputsRef[index + 1]) {
			inputsRef[index + 1].current.focus();
		}
	};

	const validateForm = () => {
		const errors = {};
		console.log(code);

		const isCodeValid = code.every((el) => el !== "");

		if (!isCodeValid) {
			errors.code = "Please fill up 6 digits of code";
		}

		setFormErrors(errors);
		return Object.keys(errors).length === 0; // Valid form if no errors/ return true or false
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const isValid = validateForm();

		if (isValid) {
			try {
				const res = await axios.post(
					`/eventAdmin/api/auth/verify?token=${token}`,
					{
						code: code.join(""),
					}
				);
				refreshUser(); // Refresh the user context
				navigate(`/eventAdmin/admin/home`);
			} catch (err) {
				setFormErrors(err.response.data);
			}
		} else {
			console.log("Form is invalid! Not submitting.", formErrors);
		}
	};

	return (
		<Body>
			<ContentContainer>
				<LogoContainer>
					<Logo src={logo} alt="Logo" />
				</LogoContainer>
				<TitleContainer>
					Enter the 6-digit code sent to your email:
				</TitleContainer>
				<FormContainer>
					<InputHorizontalContainer>
						{code.map((digit, index) => (
							<CodeInput
								key={index}
								type="text"
								value={digit}
								onChange={handleChange(index)}
								required
								maxLength="1"
								ref={inputsRef[index]} // Assign the ref
							/>
						))}
					</InputHorizontalContainer>
					{formErrors.code && <FormFeedback>{formErrors.code}</FormFeedback>}

					<SubmitButton onClick={handleSubmit}>Submit</SubmitButton>
					{/* {error && <p>{error}</p>} */}
				</FormContainer>
				<DisclaimerWrapper>
					By continuing, you agree to Dynamic's Privacy Policy and Terms of
					Service
				</DisclaimerWrapper>
				<DisclaimerWrapper>
					© 2002 - 2023 DYNAMIC MANAGEMENT GROUP LIMITED. <br /> ALL RIGHTS
					RESERVED.
				</DisclaimerWrapper>
			</ContentContainer>
		</Body>
	);
};

export default Verify;
