import React, { useContext } from "react";

import { WelcomeBox } from "../../styles/Admin";
import { AuthContext } from "../../context/authContext";

const Overview = () => {
	const { currentUser } = useContext(AuthContext);

	return <WelcomeBox>Hello, {currentUser.name}</WelcomeBox>;
};

export default Overview;
