import React, { useRef, useState, useEffect } from "react";
import {
	SearchSortForm,
	SearchSortSelect,
	SearchSortInput,
	SearchSortButton,
	SearchDiv,
	SortDiv,
} from "../../styles/Admin";

const SearchSortBar = () => {
	const [filterCriteria, setFilterCriteria] = useState("id");
	const [filter, setFilter] = useState("");
	const [sortField, setSortField] = useState("id");
	const [sortOrder, setSortOrder] = useState("ASC");

	const handleFormSubmit = (event) => {
		event.preventDefault();
	};

	return (
		<SearchSortForm onSubmit={handleFormSubmit}>
			<SearchDiv>
				<label>
					Search by:
					<SearchSortSelect
						value={filterCriteria}
						onChange={(e) => setFilterCriteria(e.target.value)}
					>
						<option value="id">ID</option>
						{/* Add other options here */}
					</SearchSortSelect>
				</label>
				<SearchSortInput
					type="text"
					value={filter}
					onChange={(e) => setFilter(e.target.value)}
					placeholder="Search..."
				/>
			</SearchDiv>
			<SortDiv>
				<label>
					Sort by:
					<SearchSortSelect
						value={sortField}
						onChange={(e) => setSortField(e.target.value)}
					>
						<option value="id">ID</option>
						{/* Add other options here */}
					</SearchSortSelect>
				</label>
				<label>
					Order:
					<SearchSortSelect
						value={sortOrder}
						onChange={(e) => setSortOrder(e.target.value)}
					>
						<option value="ASC">Ascending</option>
						<option value="DESC">Descending</option>
					</SearchSortSelect>
				</label>
			</SortDiv>
		</SearchSortForm>
	);
};

export default SearchSortBar;
