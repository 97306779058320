import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import { Html5QrcodeScanner } from "html5-qrcode";
import axios from "axios";
import MessageModal from "../../components/modal/Modal.jsx";
import { TopbarInsideModule } from "../../components/topbar/TopbarInsideModule.jsx";
import { BodyContainer2 } from "../../styles/GlobalStyles.jsx";

const QRScanner = () => {
	console.log("check in");

	const [data, setData] = useState({});
	const [modalOpen, setModalOpen] = useState(false);
	const [autoClose, setAutoClose] = useState(false);
	const [modalTemplate, setModalTemplate] = useState("scannerCheckIn");
	const [isScanning, setIsScanning] = useState(true);

	const location = useLocation();

	const qrRef = useRef(null);
	const qrScannerRef = useRef(null);

	useEffect(() => {
		if (qrRef.current && !qrScannerRef.current) {
			const qrScanner = new Html5QrcodeScanner(
				qrRef.current.id,
				{ fps: 10, qrbox: 250 },
				false
			);

			qrScanner.render((decodedText) => {
				const url = new URL(decodedText);
				const params = new URLSearchParams(url.search);
				const orderToken = params.get("token");
				qrScanner.pause(); // Pause scanning immediately after a QR code is decoded

				async function fetchData() {
					try {
						const res = await axios.get(
							`/eventAdmin/api/class/checkin/${orderToken}`
						);
						setModalOpen(true);
						setTimeout(() => {
							// setModalOpen(false);
							qrScanner.resume();
						}, 2000);
						setData(res.data);
					} catch (err) {
						setModalOpen(true);
						setTimeout(() => {
							// setModalOpen(false);
							qrScanner.resume();
						}, 2000);
					}
				}

				fetchData();
			});
			qrScannerRef.current = qrScanner;
		}

		// Cleanup function
		return () => {
			if (qrScannerRef.current) {
				console.log("Component unmounted");
				qrScannerRef.current
					.clear()
					.then(() => {
						qrScannerRef.current = null;
					})
					.catch((error) => {
						console.error("Failed to clear QR Scanner:", error);
					});
			}
		};
	}, []);

	const handleCheckIn = async (action, id) => {
		console.log(action, id);

		let status;
		switch (action) {
			case "approve":
				status = "1";
				break;
			case "cancel":
				status = "0";
				break;
			case "noshow":
				status = "9";
				break;
			case "cancelnoshow":
				status = "0";
				break;
			default:
				return;
		}

		try {
			const res = await axios.put(`/eventAdmin/api/class/checkin/id/${id}`, {
				status,
			});

			if (res.status === 200) {
				return res.data;
			}

			// qrScanner.resume();
			// setModalOpen(false);
			// qrScanner.resume();
		} catch (error) {
			console.error("Error updating status:", error);
		}

		return null;
	};

	return (
		<>
			<TopbarInsideModule
				location={`QR Code Scanner`}
				goBack={false}
				link={`/eventAdmin/admin/checkin`}
			/>
			<BodyContainer2>
				<div
					id="qr-reader"
					ref={qrRef}
					style={
						{
							// width: "100%",
							// height: "100%",
						}
					}
				></div>
				{modalOpen && (
					<MessageModal
						isOpen={modalOpen}
						template={modalTemplate}
						data={data[0]}
						autoClose={autoClose}
						setModalOpen={setModalOpen}
						handleSubmit={handleCheckIn}
					/>
				)}
			</BodyContainer2>
		</>
	);
};

export default QRScanner;
