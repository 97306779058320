import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
// import "./register.scss";
import logo from "../../assets/DMGdigi-logo.png";

import {
	Body,
	ContentContainer,
	LogoContainer,
	Logo,
	TitleContainer,
	FormContainer,
	InputContainer,
	Input,
	SubmitButton,
	FlexWrapper,
	DisclaimerWrapper,
	FormFeedback,
} from "../../styles/SignUp";

import { AuthContext } from "../../context/authContext";

const SignUp = () => {
	// In your Login component
	const { refreshUser } = useContext(AuthContext);
	const [formData, setFormData] = useState({
		email: "",
		password: "",
	});
	const [formErrors, setFormErrors] = useState({});

	const navigate = useNavigate();

	// Regular expression for email validation
	const emailRegex = /\S+@\S+\.\S+/;
	// Regular expression for password validation
	const passwordRegex =
		/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

	const validateForm = () => {
		const errors = {};

		if (!formData.email) {
			errors.email = "Email is required";
		} else if (!emailRegex.test(formData.email)) {
			errors.email = "Invalid email format";
		}

		if (!formData.password) {
			errors.password = "Password is required";
		} else if (!passwordRegex.test(formData.password)) {
			errors.password =
				"Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character";
		}

		setFormErrors(errors);
		return Object.keys(errors).length === 0; // Valid form if no errors/ return true or false
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value,
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const isValid = validateForm();

		if (isValid) {
			try {
				const res = await axios.post("/eventAdmin/api/auth/login", formData);
				console.log(res.data);
				refreshUser(); // Refresh the user context
				navigate(`/eventAdmin/admin/home`);
			} catch (err) {
				setFormErrors(err.response.data);
			}
		} else {
			console.log("Form is invalid! Not submitting.", formErrors);
		}
	};

	return (
		<Body>
			<ContentContainer>
				<LogoContainer>
					<Logo src={logo} alt="Logo" />
				</LogoContainer>
				<TitleContainer>Login to your Event Panel now!</TitleContainer>
				<FormContainer>
					<Input
						required
						type="email"
						placeholder="Email"
						name="email"
						value={formData.email}
						onChange={handleChange}
					/>
					{formErrors.email && <FormFeedback>{formErrors.email}</FormFeedback>}
					<Input
						required
						type="text"
						placeholder="Password"
						name="password"
						value={formData.password}
						onChange={handleChange}
					/>
					{formErrors.password && (
						<FormFeedback>{formErrors.password}</FormFeedback>
					)}

					<SubmitButton onClick={handleSubmit}>Login</SubmitButton>
					{/* {error && <p>{error}</p>} */}
				</FormContainer>
				<FlexWrapper>
					{/* <div>
						<Link to="/register">Register </Link>
					</div>
					<div> | </div>
					<div>
						<Link to="/login">Forgot password</Link>
					</div> */}
				</FlexWrapper>
				<DisclaimerWrapper>
					By continuing, you agree to Dynamic's Privacy Policy and Terms of
					Service
				</DisclaimerWrapper>
				<DisclaimerWrapper>
					© 2002 - 2023 DYNAMIC MANAGEMENT GROUP LIMITED. <br /> ALL RIGHTS
					RESERVED.
				</DisclaimerWrapper>
			</ContentContainer>
		</Body>
	);
};

export default SignUp;
