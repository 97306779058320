import React, { useRef, useState, useEffect } from "react";
import StickyTopTab from "../../components/stickyTopTab/StickyTopTab.jsx";
import ChangingData from "../../components/students/ChangingStudentData.jsx";
import SearchSortBar from "../../components/orders/SearchSortBar.jsx";
import axios from "axios";

const Students = () => {
	const TabContext = React.createContext();
	const [tabValues, setTabValues] = useState([
		{
			name: "tab_1",
			label: "All Students",
		},
		{
			name: "tab_2",
			label: "New Students in the Last 7 Days",
		},
	]);

	const [data, setData] = useState([]);
	const [filterCriteria, setFilterCriteria] = useState("id");
	const [filter, setFilter] = useState("");
	const [sortField, setSortField] = useState("id");
	const [sortOrder, setSortOrder] = useState("ASC");

	useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await axios.get(
					`/eventAdmin/api/student?filter=${filterCriteria}&value=${filter}&sort=${sortField}&order=${sortOrder}`
				);

				// const modifiedData = res.data.map((event) => {
				// 	const datetimeArray = JSON.parse(event.datetime);

				// 	datetimeArray.forEach((element) => {
				// 		const date = element.date.substring(0, 10);
				// 		return { ...element, date: date };
				// 	});
				// });
				setData(res.data);
			} catch (err) {
				console.log(err);
			}
		};

		fetchData();
		// setResultChange("All");

		// const intervalId = setInterval(fetchData, 1000);

		// Clean up the interval when the component unmounts or when dependencies change
		// return () => {
		// 	clearInterval(intervalId);
		// };
	}, [filterCriteria, filter]);

	const handleFilterChange = (name) => {
		if (name === "All Students") {
			setFilter("");
			setFilterCriteria("id");
		} else {
			if (name === "New Students in the Last 7 Days") {
				console.log(name);
				setFilter(7);
				setFilterCriteria("created_at");
			}
		}
	};

	return (
		<TabContext.Consumer>
			{(tab) => (
				<>
					{tabValues ? (
						<StickyTopTab
							tabValues={tabValues}
							tabValue={tab}
							changeTabAction={handleFilterChange}
						/>
					) : null}

					<SearchSortBar />
					<ChangingData data={data} />
				</>
			)}
		</TabContext.Consumer>
	);
};

export default Students;
