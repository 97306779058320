import React, { useRef, useState, useEffect } from "react";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment-timezone";

import {
	FormContainer,
	InputContainer,
	Input,
	SubmitButton,
	FormFeedback,
	InputHorizontalContainer,
	GroupInputContainer,
	FormLabel,
	TextArea,
} from "../../styles/SignUp";

import {
	ContentContainer,
	DateContainer,
	TimeContainer,
	StyledDatePicker,
	StyledDateTime,
	FixedBottomContainer,
	OptionListContainer,
	OptionList,
	OptionListIconContainer,
	OptionListInput,
	ModuleContainer,
	DueDateContainer,
	StepContainer,
	OptionListValueContainer,
	SubHeader,
	SessionTitle,
	LoadingOverlay,
	LoadingSpinner,
} from "../../styles/Admin";

import { BodyContainer2 } from "../../styles/GlobalStyles";

import { TopbarInsideModule } from "../../components/topbar/TopbarInsideModule";

import StickyBottomSaveBar from "../../components/stickyBottomActionBar/stickyBottomSaveBar";

import axios from "axios";

const EditProgram = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const fields = queryParams.get("field").split(",");
	const { id } = useParams();

	const navigate = useNavigate();

	const minDate = new Date();

	console.log(minDate);

	const [data, setData] = useState({});
	const [updatedData, setUpdatedData] = useState({});
	const [formErrors, setFormErrors] = useState({});
	const [loadingCircle, setLoadingCircle] = useState(false);
	const [fieldLabel, setFieldLabel] = useState([]);
	const [isDueDateCalenderOpen, setIsDueDateCalenderOpen] = useState(false);
	const [selectedOption, setSelectedOption] = useState({
		option1: "One Day",
		option2: "Open",
	});

	//Get the Program
	useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await axios.get(
					`/eventAdmin/api/class/getProgram?id=${id}`
				);

				setData(res.data);

				const fields = queryParams?.get("field").split(",");
				for (let field of fields) {
					let fieldValue = res.data[field];

					if (field === "duedate") {
						fieldValue =
							moment(fieldValue).format("YYYY-MM-DD HH:mm:ss") ||
							moment(new Date())
								.hour(12)
								.minute(0)
								.second(0)
								.format("YYYY-MM-DD HH:mm:ss");
					}

					setUpdatedData((prev) => ({
						...prev,
						[field]: fieldValue,
					}));
				}
			} catch (err) {
				console.log(err);
			}
		};

		fetchData();
	}, []);

	console.log(updatedData);

	// Change the field label name
	useEffect(() => {
		console.log(fields);
		let newFieldLabels = [];

		fields?.map((field) => {
			let label;
			switch (field) {
				case "name":
					label = "Name";
					break;
				case "name_chi":
					label = "Name in Chi";
					break;
				case "category":
					label = "Category";
					break;
				// Add more cases as needed
				case "description":
					label = "Description";
					break;

				case "duedate":
					label = "Due Date";
					break;
				case "open":
					label = "Due Date";
					break;
				default:
					label = "";
					break;
			}
			newFieldLabels.push({ field: field, label: label });
		});
		setFieldLabel(newFieldLabels);
	}, []);

	console.log(fieldLabel);

	const handleChange = (e) => {
		console.log("handle change");
		const { name, value } = e.target;

		let formattedValue = value;

		setUpdatedData((prevFormData) => ({
			...prevFormData,
			[name]: formattedValue,
		}));
	};

	console.log(updatedData);

	const validateForm = () => {
		const errors = {};

		// if (!updatedData.name || updatedData?.name.trim() === "") {
		// 	errors.name = "Name is required";
		// }

		// if (!formData.code) {
		// 	errors.code = "Programe code is required";
		// }

		// if (!formData.duedate) {
		// 	errors.duedate = "Date is required";
		// }

		// if (!formData.venue) {
		// 	errors.venue = "Venue is required";
		// }

		// if (!formData.instructor) {
		// 	errors.instructor = "Instructor is required";
		// }

		// if (!formData.category) {
		// 	errors.category = "Category is required";
		// }

		setFormErrors(errors);
		return Object.keys(errors).length === 0; // Valid form if no errors/ return true or false
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const isValid = validateForm();

		if (isValid) {
			setLoadingCircle(true);

			try {
				const res = await axios.put(
					`/eventAdmin/api/class/editProgram/${id}`,
					updatedData
				);

				setLoadingCircle(false);

				navigate(`/eventAdmin/admin/program/${id}`);

				// setModalIsOpen(false);
				// setAlbums((prev) => ({
				// 	...prev,
				// 	name: res.data.name,
				// 	expired_on: res.data.expired_on,
				// }));
			} catch (err) {
				// setError(err.response.data);
			}
		}
	};

	const CustomDueDateInput = React.forwardRef(
		({ value, onClick, index }, ref) => (
			<input
				type="text"
				style={{
					width: "100%",
					height: "40px",
					color: "#ffffff",
					textAlign: "right",
					// position: "absolute",
					// top: "0",
					// right: "10px",
					// bottom: "-13px",
					border: "none",
					backgroundColor: "transparent",
				}}
				required
				name="duedate"
				value={moment(updatedData.duedate).format("YYYY-MM-DD")}
				readOnly
				onClick={(event) => {
					event.preventDefault();
					setIsDueDateCalenderOpen(!isDueDateCalenderOpen);
					onClick(event);
				}}
				onBlur={(event) => {
					setIsDueDateCalenderOpen(false);
				}}
				ref={ref}
			/>
		)
	);

	const handleOptionClick = (optionName, optionValue) => {
		setSelectedOption((prev) => ({
			...prev,
			[optionName]: optionValue,
		}));
	};

	return (
		<>
			<TopbarInsideModule
				location={`Edit ${data.name}`}
				goBack={true}
				link={`/eventAdmin/admin/program/${id}`}
			/>
			<BodyContainer2>
				<ContentContainer padding="10px">
					<FormContainer>
						<InputContainer>
							{fieldLabel?.map((field) => (
								<>
									<SessionTitle>{field.label}</SessionTitle>
									{!updatedData.description && !updatedData.duedate && (
										<Input
											required
											type="text"
											placeholder={fieldLabel}
											name={field.field}
											value={updatedData[field.field] || ""}
											onChange={handleChange}
										/>
									)}

									{updatedData.description && (
										<TextArea
											required
											type="text"
											placeholder="Course Description"
											name={field.field}
											value={updatedData[field.field] || ""}
											onChange={handleChange}
										/>
									)}

									{updatedData.duedate && (
										<DueDateContainer>
											<OptionListContainer>
												<OptionList
													selected={updatedData.open}
													onClick={() => handleOptionClick("option2", "Open")}
												>
													Open Until
													<DateContainer>
														<StyledDatePicker
															selected={new Date(updatedData.duedate)}
															onChange={(date) =>
																handleChange({
																	target: { name: "duedate", value: date },
																})
															}
															dateFormat="MM/dd/yyyy"
															minDate={minDate}
															customInput={<CustomDueDateInput />}
															open={isDueDateCalenderOpen}
														/>
													</DateContainer>
													{formErrors.duedate && (
														<FormFeedback>{formErrors.duedate}</FormFeedback>
													)}
												</OptionList>

												<OptionList
													selected={!updatedData.open}
													// onClick={() => handleOptionClick("option2", "closed")}
												>
													Closed
												</OptionList>
											</OptionListContainer>
										</DueDateContainer>
									)}
								</>
							))}

							{/* <SessionTitle>{fieldLabel[field]}</SessionTitle>
							{!updatedData.description && !updatedData.duedate && (
								<Input
									required
									type="text"
									placeholder={fieldLabel}
									name={field}
									value={updatedData[field] || ""}
									onChange={handleChange}
								/>
							)}
							{updatedData.description && (
								<TextArea
									required
									type="text"
									placeholder={fieldLabel}
									name={field}
									value={updatedData[field] || ""}
									onChange={handleChange}
								/>
							)}
							{updatedData.duedate && (
								<DueDateContainer>
									<OptionListContainer>
										<OptionList
											selected={updatedData.open}
											// onClick={() => handleChange("option2", "Open")}
										>
											Open Until
											<DateContainer>
												<StyledDatePicker
													selected={new Date(updatedData.duedate || null)}
													onChange={(date) =>
														handleChange({
															target: { name: "duedate", value: date },
														})
													}
													dateFormat="MM/dd/yyyy"
													minDate={minDate}
													customInput={<CustomDueDateInput />}
													open={isDueDateCalenderOpen}
												/>
											</DateContainer>
											{formErrors.duedate && (
												<FormFeedback>{formErrors.duedate}</FormFeedback>
											)}
										</OptionList>

										<OptionList
											selected={!updatedData.open}
											// onClick={() => handleOptionClick("option2", "closed")}
										>
											Closed
										</OptionList>
									</OptionListContainer>
								</DueDateContainer>
							)}
							{formErrors[field] && (
								<FormFeedback>{formErrors[field]}</FormFeedback>
							)} */}
						</InputContainer>
					</FormContainer>
				</ContentContainer>
			</BodyContainer2>
			<StickyBottomSaveBar handleSubmit={handleSubmit} />

			{/***************************************
		    // LOADING CIRCLE //
		***************************************/}
			{loadingCircle && (
				<LoadingOverlay>
					<LoadingSpinner />
				</LoadingOverlay>
			)}
		</>
	);
};

export default EditProgram;
