import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import {
	ContentContainer,
	EventWrapper,
	EventTextWrapper,
	EventTitle,
	EventDate,
	EventPicWrapper,
	EventPic,
	TableContainer,
	Table,
	TableHeader,
	TableRow,
	TableCell,
	ProgramCell,
	PendingStatus,
	ConfirmedStatus,
} from "../../styles/Admin";

const Courses = ({ data }) => {
	const [courses, setCourses] = useState(data.courses);

	useEffect(() => {
		setCourses(data.courses);
	}, [data]);

	return (
		<ContentContainer>
			<TableContainer>
				<Table>
					<thead>
						<TableRow>
							<TableHeader>Status</TableHeader>
							<TableHeader>Course code</TableHeader>
							<TableHeader>Option 1</TableHeader>
							<TableHeader>Option 2</TableHeader>
							<TableHeader>Course fee</TableHeader>
							<TableHeader>No. of classes</TableHeader>
							<TableHeader>Capacity</TableHeader>
							{/* Add other headers here */}
						</TableRow>
					</thead>
					<tbody>
						{courses?.map((course) => (
							<TableRow key={course.course_id}>
								<TableCell>
									{course.status ? (
										<ConfirmedStatus>Confirmed</ConfirmedStatus>
									) : (
										<PendingStatus>Pending</PendingStatus>
									)}
								</TableCell>
								<TableCell>{course.code}</TableCell>
								<TableCell>{course.option1value}</TableCell>
								<TableCell>{course.option2value}</TableCell>
								<TableCell>{course.fee}</TableCell>
								<TableCell>{course.noofclasses}</TableCell>
								<TableCell>{course.capacity}</TableCell>
								{/* Add other cells here */}
							</TableRow>
						))}
					</tbody>
				</Table>
			</TableContainer>
		</ContentContainer>
	);
};

export default Courses;
