import React, { useState, useEffect, useContext } from "react";
import CircularProgress from "@material-ui/core/CircularProgress"; // Import the loading circle component from Material-UI

// import DatePicker from "react-datepicker";
// import "react-datetime/css/react-datetime.css";
// import "react-datepicker/dist/react-datepicker.css";
import { Success } from "../../assets/Calender.js";
import dayjs, { Dayjs } from "dayjs";
import "react-datepicker/dist/react-datepicker.css";
// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { DateField } from "@mui/x-date-pickers/DateField";

import {
	ContentContainer,
	ClassInfoWrapper,
	ClassTitle,
	ClassDetailsStyled,
	DetailValue,
	Label,
	OptionListPrice,
	Subheader,
	ImageContainer,
	StatusContainer,
	StatusText,
	LoadingOverlay,
	LoadingSpinner,
	ModalBackground,
	ModalContent,
	BookingStatusStyled,
	HighlightContainer,
	PendingStatus,
	ConfirmedStatus,
	CancelStatus,
	CheckedInStatus,
} from "../../styles/Admin";

import { SubmitButton } from "../../styles/SignUp.js";

export const CheckInModal = ({ data, handleSubmit, selectCourse }) => {
	// const [value, setValue] =
	//   (React.useState < Dayjs) | (null > dayjs("2022-04-17"));

	const [isCheckInSuccessful, setIsCheckInSuccessful] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const handleClick = async (action, id) => {
		setIsLoading(true);
		const result = await handleSubmit(action, id); // handleCheckIn should return a promise that resolves to a boolean indicating whether the check-in was successful
		console.log(result);
		if (result) {
			setIsCheckInSuccessful(true);
		}
		setIsLoading(false);
	};

	return (
		<>
			<HighlightContainer>
				<Label> {data?.course_code}</Label>
				<div>{data?.program_name}</div>
			</HighlightContainer>
			<HighlightContainer hightlightBorder={"black"}>
				{data?.participant_info.firstname} {data?.participant_info.lastname}
			</HighlightContainer>
			<DetailValue> {}</DetailValue>
			<DetailValue> </DetailValue>
			{data?.attendence_details.map((attendence, index) => {
				const date = new Date(attendence.class_date);
				const formattedDate = date.toLocaleDateString();
				const formattedTime = date.toLocaleTimeString([], {
					hour: "2-digit",
					minute: "2-digit",
				});

				return (
					<>
						<HighlightContainer>
							<div>Class {index + 1}</div>
							<div> {formattedDate}</div>
							<div>{formattedTime} </div>
						</HighlightContainer>
						{attendence.status === 0 ? (
							<SubmitButton
								onClick={() =>
									handleClick("approve", attendence.class_attendence_id)
								}
								action="approve"
								disabled={isLoading}
							>
								{isLoading ? (
									<CircularProgress size={24} />
								) : isCheckInSuccessful ? (
									<>
										<Success fill="white" /> Checked In
									</>
								) : (
									"Check In"
								)}
							</SubmitButton>
						) : attendence.status === 1 ? (
							<CheckedInStatus>
								<Success />
								Checked in
							</CheckedInStatus>
						) : attendence.status === 9 ? (
							<CancelStatus>No Show</CancelStatus>
						) : null}
					</>
				);
			})}
		</>
	);
};
