import React from "react";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import MessageModal from "../../components/modal/Modal.jsx";

import {
	GlobalStyles,
	BodyContainer2,
	FixedContainer,
} from "../../styles/GlobalStyles";

import {
	ClassInfoWrapper,
	ClassTitle,
	ClassDetailsStyled,
	DetailValue,
	Label,
	OptionListPrice,
	Subheader,
	ImageContainer,
	StatusContainer,
	StatusText,
	LoadingOverlay,
	LoadingSpinner,
	ModalBackground,
	ModalContent,
} from "../../styles/Admin";

import { TopbarInsideModule } from "../../components/topbar/TopbarInsideModule";

import {
	FormContainer,
	InputContainer,
	Input,
	TextArea,
	SubmitButton,
	FormFeedback,
	InputHorizontalContainer,
	GroupInputContainer,
	FormLabel,
} from "../../styles/SignUp.js";

import {
	DateContainer,
	TimeContainer,
	StyledDatePicker,
	StyledDateTime,
	FixedBottomContainer,
	OptionListContainer,
	OptionList,
	OptionListIconContainer,
	OptionListInput,
	ModuleContainer,
	DueDateContainer,
	StepContainer,
	OptionListValueContainer,
} from "../../styles/Admin.jsx";

const DynamicallyChangeHeightContainer = () => {
	const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

	useEffect(() => {
		const handleResize = () => {
			setViewportHeight(window.innerHeight);
		};

		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return viewportHeight;
};

const Order = () => {
	const { id, token } = useParams();
	const viewportHeight = DynamicallyChangeHeightContainer(); // This will give the dynamic viewport height

	const [data, setData] = useState([]);

	const [loadingCircle, setLoadingCircle] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const [modalMessage, setModalMessage] = useState("");

	const statusMap = {
		0: "Pending",
		1: "Approved",
		9: "Cancelled",
	};

	const statusMessages = {
		0: "This order is pending approval",
		1: "This order is approved!",
		9: "This order is cancelled!",
	};

	const handleSubmit = async (event, action) => {
		event.preventDefault();
		let newStatus;
		if (action === "approve") {
			newStatus = 1;
		} else if (action === "cancel") {
			newStatus = 9;
		} else if (action === "pending") {
			newStatus = 0;
		}

		let endpoint;
		if (id) {
			endpoint = `/eventAdmin/api/orders/${id}`;
		} else if (token) {
			endpoint = `/eventAdmin/api/orders/token/${token}`;
		}

		try {
			const res = await axios.put(endpoint, {
				status: newStatus,
			});

			console.log(res.data);

			// Update the data state with the new status
			setData((prevData) => ({
				...prevData,
				status: res.data.status,
			}));

			setLoadingCircle(false);
			setModalOpen(true);
			setModalMessage(statusMessages[Number(res.data.status)]);
			setTimeout(() => {
				setModalOpen(false);
			}, 3000);
		} catch (err) {
			// setError(err.response.data);
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			let endpoint;
			if (id) {
				endpoint = `/eventAdmin/api/orders/${id}`;
			} else if (token) {
				endpoint = `/eventAdmin/api/orders/token/${token}`;
			}

			try {
				const res = await axios.get(endpoint);

				// res.data.duedate = res.data.duedate.substring(0, 10);

				setData(res.data);
			} catch (err) {
				console.log(err);
			}
		};

		fetchData();
		// setResultChange("All");

		// const intervalId = setInterval(fetchData, 1000);

		// Clean up the interval when the component unmounts or when dependencies change
		// return () => {
		// 	clearInterval(intervalId);
		// };
	}, [id, data.status]);

	return (
		<FixedContainer height={viewportHeight}>
			<TopbarInsideModule
				location={id ? `Order ${id}` : `Order ${data.id}`}
				goBack={true}
			/>
			<BodyContainer2>
				<ClassInfoWrapper>
					<StatusContainer status={data.status}>
						{/* <Clock /> */}
						<StatusText>{statusMap[data.status]}</StatusText>
					</StatusContainer>
					<Label> {data.course_code}</Label>
					<ClassTitle>{data.program_name}</ClassTitle>

					<ClassDetailsStyled>
						<DetailValue> {data.course_option1value}</DetailValue>
					</ClassDetailsStyled>

					<OptionListPrice>HK${data.price}</OptionListPrice>
				</ClassInfoWrapper>

				<ClassInfoWrapper>
					<Subheader>Student Info</Subheader>
					<Label>ST {data.user_member_id}</Label>
					<ClassDetailsStyled>
						<DetailValue>
							{data.user_member_firstname} {data.user_member_lastname}
						</DetailValue>
					</ClassDetailsStyled>
				</ClassInfoWrapper>

				<ClassInfoWrapper>
					<Subheader>Contact Info</Subheader>

					<ClassDetailsStyled>
						<DetailValue> {data.contact}</DetailValue>
					</ClassDetailsStyled>
				</ClassInfoWrapper>

				<ClassInfoWrapper>
					<Subheader>Payment Receipt</Subheader>
					<ImageContainer>
						<img
							src={`data:image/jpeg;base64,${data.image}`}
							alt="receipt"
							style={{ width: "100%" }}
						/>
					</ImageContainer>
				</ClassInfoWrapper>

				<FixedBottomContainer>
					<SubmitButton
						action="approve"
						onClick={(e) => handleSubmit(e, "approve")}
					>
						Approve
					</SubmitButton>
					{/* <SubmitButton
						action="pending"
						onClick={(e) => handleSubmit(e, "pending")}
					>
						Pending
					</SubmitButton> */}
					<SubmitButton
						action="cancel"
						onClick={(e) => handleSubmit(e, "cancel")}
					>
						Cancel
					</SubmitButton>
				</FixedBottomContainer>
			</BodyContainer2>

			{modalOpen && (
				<MessageModal
					isOpen={modalOpen}
					message={modalMessage}
					autoClose={true}
				/>
			)}
			{/************************************** 
            // LOADING CIRCLE //
        ***************************************/}
			{loadingCircle && (
				<LoadingOverlay>
					<LoadingSpinner />
				</LoadingOverlay>
			)}
		</FixedContainer>
	);
};

export default Order;
