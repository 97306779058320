import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
	SuccessSubmission,
	FailSubmission,
	AddCourse,
	ScannerCheckInPopUp,
} from "./ModalMessage";
import { CrossX } from "../../assets/Calender";

export const ModalBackground = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.6);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2147483647 !important;
`;

export const ModalContent = styled.div`
	background: white;
	padding: 20px;
	border-radius: 10px;
	max-width: 500px;
	width: 90%;
	// max-height: 70%;
	// overflow-y: auto; // Change this line
	position: fixed;
`;

export const CloseContainer = styled.div`

width:100%;
text-align:right;
margin-bottom:20px;



svg{
width:20px;
height20px;
}


`;

export const MessageModal = ({
	isOpen,
	setModalOpen,
	autoClose,
	template,
	language,
	formData,
	setFormData,
	handleSubmit,
	selectCourse,
	data,
}) => {
	// const [modalOpen, setModalOpen] = useState(isOpen);

	// Effect to sync internal state with the isOpen prop
	useEffect(() => {
		setModalOpen(isOpen);
	}, [isOpen]);

	useEffect(() => {
		let timer;
		if (autoClose && isOpen) {
			timer = setTimeout(() => {
				setModalOpen(false);
			}, 5000);
		}

		// Cleanup the timer when the component unmounts or modal state changes
		return () => clearTimeout(timer);
	}, [autoClose, isOpen]);

	const toggleModal = () => {
		setModalOpen(!isOpen);
	};

	if (!isOpen) return null;

	return (
		<ModalBackground onClick={toggleModal}>
			<ModalContent onClick={(e) => e.stopPropagation()}>
				{!autoClose && (
					<CloseContainer>
						<CrossX onClick={toggleModal} />
					</CloseContainer>
				)}
				{template === "success" && <SuccessSubmission language={language} />}
				{template === "fail" && <FailSubmission language={language} />}
				{template === "addCourse" && (
					<AddCourse
						// language={language}
						formData={formData}
						setFormData={setFormData}
						handleSubmit={handleSubmit}
						selectCourse={selectCourse}
						// isOpen={isOpen}
						// setModalOpen={setModalOpen}
					/>
				)}
				{template === "scannerCheckIn" && (
					<ScannerCheckInPopUp data={data} handleSubmit={handleSubmit} />
				)}
			</ModalContent>
		</ModalBackground>
	);
};

export default MessageModal;
