import styled from "styled-components";

//BURGER
export const Burger = styled.div`
  z-index: 99999 !important; //Must be larger than the Slidebar!!!
  width: 2rem;
  height: 2rem;
  position: relative;
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  cursor: pointer;

  div {
    width: 2rem;
    height: 0.25rem;
    background-color: ${({ open }) => (open ? "white" : "#F8F9F9")};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.1s linear;

    &:nth-child(1) {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(-180)")};
    }

    &:nth-child(2) {
      transform: ${({ open }) => (open ? "translateY(100%)" : "translateX(0)")};
      opacity: ${({ open }) => (open ? 0 : 1)};
    }

    &:nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(-180)")};
    }
  }
`;

export const Sidebar = styled.div`
  /* border: 2px yellow solid; */
  z-index: 9999 !important; //Must be smaller than the BURGER!!!
  position: absolute;
  top: 0;
  right: 0;
  visibility: ${({ open }) => (open ? "visible" : "hidden")};
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
  transition: transform 0.1s ease-in-out;
  height: 100vh;
  width: 100vw;
  background-color: #2e4053;
`;

export const NavWrapper = styled.div`
  /* border: 1px white solid; */
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LinksBox = styled.div`
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100vh;

  ul {
    padding: 0;
  }
  li {
    padding: 0;
    list-style: none;
  }
  a {
    text-decoration: none;
    color: #eaf2f8;

    &:hover {
      color: #f8f9fa;
    }

    &:active {
      color: #f8f9fa;
    }

    &:visited {
      text-decoration: none;
      color: #eaf2f8;
    }
  }

  h1 {
    font-size: clamp(1.5rem, 5vw, 3.125rem);
    text-align: center;
    text-transform: uppercase;
    color: #eaf2f8;
    letter-spacing: 1px;
    font-family: serif;
    font-weight: 400;
    line-height: 1.5em;

    br {
      display: none;
    }

    @media (max-width: 480px) {
      font-size: 18px;

      br {
        display: block;
      }
    }
  }
`;
