import React, { useRef, useState, useEffect } from "react";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import { TopbarInsideModule } from "../../components/topbar/TopbarInsideModule.jsx";
import CheckinClass from "../../components/checkin/CheckinClass.jsx";

import StickyTopTab from "../../components/stickyTopTab/StickyTopTab.jsx";

import axios from "axios";

import { BodyContainer2 } from "../../styles/GlobalStyles.jsx";

import { SubmitButton } from "../../styles/SignUp.js";

import {
	ContentContainer,
	ClassInfoWrapper,
	ClassTitle,
	ClassDetailsStyled,
	DetailValue,
	Label,
	OptionListPrice,
	Subheader,
	ImageContainer,
	StatusContainer,
	StatusText,
	LoadingOverlay,
	LoadingSpinner,
	ModalBackground,
	ModalContent,
	BookingStatusStyled,
	CheckInStatus,
	FixedBottomContainer,
} from "../../styles/Admin";

const CourseCheckIn = () => {
	const [data, setData] = useState({});
	const [orderDetails, setOrderDetails] = useState({});
	const [checkinStatus, setCheckinStatus] = useState(false);

	const [selectCheckin, setSelectCheckin] = useState({});

	const location = useLocation();
	const navigate = useNavigate();
	const queryParams = new URLSearchParams(location.search);

	// const fields = queryParams.get("field").split(",");
	// const id = location.pathname.split("/")[4];

	const { id } = useParams();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await axios.get(
					`/eventAdmin/api/class/checkin/class/${id}`
				);
				setData(res.data[0]);
				// setClassId();
				setOrderDetails(res.data[0].orders);
			} catch (err) {
				console.log(err);
			}
		};

		fetchData();
	}, [checkinStatus]);

	// useEffect(() => {
	// 	const fetchData = async () => {
	// 		try {
	// 			const res = await axios.get(
	// 				`/eventAdmin/api/class/checkin/class/${classId}}`
	// 			);

	// 			setData(res.data[0]);
	// 			setOrderDetails(res.data[0].orderDetails);
	// 		} catch (err) {
	// 			console.log(err);
	// 		}
	// 	};

	// 	fetchData();
	// }, [filterCriteria, filter]);

	console.log(selectCheckin);

	const handleCheckin = async (action) => {
		console.log("handleCheckin", action);

		let status;
		switch (action) {
			case "approve":
				status = "1";
				break;
			case "cancel":
				status = "0";
				break;
			case "noshow":
				status = "9";
				break;
			case "cancelnoshow":
				status = "0";
				break;
			default:
				return;
		}

		const class_attendence_id = selectCheckin.class_attendence_id;

		// Send the status and class_attendence_id to the backend
		try {
			const res = await axios.put(
				`/eventAdmin/api/class/checkin/id/${class_attendence_id}`,
				{ status }
			);
			// setModalOpen(true);
			// setModalMessage(res.data);
			// setTimeout(() => {
			// 	setModalOpen(false);
			// 	qrScanner.resume();
			// }, 5000);
			setCheckinStatus(!checkinStatus);
			setSelectCheckin({});

			console.log(res.data);
			// setData(res.data);
		} catch (error) {
			console.log(error);
			if (error.response.status === 401 || error.response.status === 403) {
				navigate(`/eventAdmin/users/login`);
			} else {
				console.error("Error updating status:", error);
			}
		}
	};

	return (
		<>
			<TopbarInsideModule
				location={data?.course_code ? `Check in ${data?.course_code}` : ""}
				goBack={true}
				link={`/eventAdmin/admin/checkin`}
				icon={"scanner"}
			/>

			<BodyContainer2>
				{data?.course_code ? (
					<ContentContainer>
						<ClassInfoWrapper>
							<Label> {data?.course_code}</Label>
							<ClassTitle>{data?.program_name}</ClassTitle>

							<ClassDetailsStyled>
								<DetailValue> {data?.course_option1value}</DetailValue>
								<DetailValue> {data?.course_option2value}</DetailValue>
								{/* <DetailValue>
								{" "}
								Total {data?.course_noofclasses} classes
							</DetailValue> */}
							</ClassDetailsStyled>
							<BookingStatusStyled>
								<DetailValue>
									{" "}
									Enrolled: {data?.actual_enrollment}/{data?.course_capacity}
								</DetailValue>
							</BookingStatusStyled>

							{/* <OptionListPrice>
							{data?.fee === "0" ? "Free" : `HK$ ${data?.fee}`}
						</OptionListPrice> */}
						</ClassInfoWrapper>

						<ClassInfoWrapper>
							<Subheader>Class Check-in</Subheader>
							<BookingStatusStyled color="green">
								<DetailValue>
									Checked In:
									{data && data.orders
										? data.orders.reduce(
												(count, order) =>
													order.attendenceDetails &&
													order.attendenceDetails[0] &&
													order.attendenceDetails[0].status === 1
														? count + 1
														: count,
												0
										  )
										: 0}
									/{data?.actual_enrollment}
								</DetailValue>
							</BookingStatusStyled>
							<CheckinClass
								data={
									orderDetails && orderDetails.length > 0 ? orderDetails : []
								}
								setSelectCheckin={setSelectCheckin}
								selectCheckin={selectCheckin}
								handleCheckin={handleCheckin}
							/>
						</ClassInfoWrapper>
					</ContentContainer>
				) : (
					<ContentContainer>No enrolment for this course</ContentContainer>
				)}
			</BodyContainer2>

			<FixedBottomContainer>
				{selectCheckin && selectCheckin.status === 0 && (
					<>
						<SubmitButton
							onClick={() => handleCheckin("approve")}
							action="approve"
						>
							Check In
						</SubmitButton>
						<SubmitButton
							onClick={() => handleCheckin("noshow")}
							action="noshow"
						>
							Mark No Show
						</SubmitButton>
					</>
				)}
				{selectCheckin && selectCheckin.status === 1 && (
					<SubmitButton onClick={() => handleCheckin("cancel")} action="cancel">
						Cancel Check In
					</SubmitButton>
				)}
				{selectCheckin && selectCheckin.status === 9 && (
					<SubmitButton
						onClick={(event) => handleCheckin("cancelnoshow")}
						action="noshow"
					>
						Cancel No Show
					</SubmitButton>
				)}
			</FixedBottomContainer>

			{/* <SearchSortBar /> */}
			{/* // <ProgramChangingContent tab={currentTab} data={data} /> */}

			{/* {modalOpen && (
				<MessageModal
					isOpen={modalOpen}
					template={modalTemplate}
					language={"en"}
					autoClose={autoClose}
					setModalOpen={setModalOpen}
					formData={formData}
					setFormData={setFormData}
					handleSubmit={handleSubmit}
					selectCourse={selectCourse}
				/>
			)} */}
		</>

		// 	{/***************************************
		//     // LOADING CIRCLE //
		// ***************************************/}
		// 	{loadingCircle && (
		// 		<LoadingOverlay>
		// 			<LoadingSpinner />
		// 		</LoadingOverlay>
		// 	)}
		// // </>
	);
};

export default CourseCheckIn;
