import React, { useState, useEffect, useContext } from "react";
// import DatePicker from "react-datepicker";
// import "react-datetime/css/react-datetime.css";
// import "react-datepicker/dist/react-datepicker.css";
import { Calender } from "../../assets/Calender.js";
import dayjs, { Dayjs } from "dayjs";
import "react-datepicker/dist/react-datepicker.css";
// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { DateField } from "@mui/x-date-pickers/DateField";

import {
	FormContainer,
	InputContainer,
	Input,
	TextArea,
	SubmitButton,
	FormFeedback,
	InputHorizontalContainer,
	GroupInputContainer,
	FormLabel,
} from "../../styles/SignUp.js";

import {
	DateContainer,
	TimeContainer,
	StyledDatePicker,
	StyledDateTime,
	FixedBottomContainer,
	OptionListContainer,
	OptionList,
	OptionListIconContainer,
	OptionListInput,
	ModuleContainer,
	DueDateContainer,
	StepContainer,
	OptionListValueContainer,
} from "../../styles/Admin.jsx";

export const AddCourseForm = ({
	formData,
	setFormData,
	handleSubmit,
	selectCourse,
	withSubmitBtn,
	errors,
	language,
}) => {
	// const [value, setValue] =
	//   (React.useState < Dayjs) | (null > dayjs("2022-04-17"));

	console.log(formData);

	const [selectedOption, setSelectedOption] = useState({
		option1: "Open",
		option2: "Closed",
	});

	const minDate = new Date();

	const handleOptionClick = (optionName, optionValue) => {
		setFormData((prevFormData) => ({
			...prevFormData,
			[optionName]: optionValue,
		}));
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value,
		}));
	};

	const [formErrors, setFormErrors] = useState({});

	const validateForm = () => {
		const errors = {};

		if (!formData.course_code) {
			errors.course_code = "Course code is required";
		}
		if (!formData.course_capacity) {
			errors.course_capacity = "Capacity is required";
		}

		if (!formData.course_option1value) {
			errors.course_option1value = "Option1value is required";
		}

		if (!formData.course_fee) {
			errors.course_fee = "Fee is required";
		}

		setFormErrors(errors);
		return Object.keys(errors).length === 0; // Valid form if no errors/ return true or false
	};

	const handleClick = () => {
		const isValid = validateForm();

		console.log(formData);
		if (isValid) {
			handleSubmit();
		}
	};

	return (
		<>
			<FormContainer>
				<InputContainer>
					<Input
						required
						type="text"
						placeholder="Course Code"
						name="course_code"
						value={formData?.course_code || null}
						onChange={handleChange}
						disabled={selectCourse}
					/>
					{formErrors.course_code && (
						<FormFeedback>{formErrors.course_code}</FormFeedback>
					)}
				</InputContainer>

				<InputContainer>
					<Input
						required
						type="text"
						placeholder="Course Capacity"
						name="course_capacity"
						value={formData?.course_capacity || null}
						onChange={handleChange}
					/>
					{formErrors.course_capacity && (
						<FormFeedback>{formErrors.course_capacity}</FormFeedback>
					)}
				</InputContainer>

				<InputContainer>
					<Input
						required
						type="text"
						placeholder="No. of classes"
						name="course_noofclasses"
						value={formData?.course_noofclasses || null}
						onChange={handleChange}
						disabled={selectCourse}
					/>
					{formErrors.course_noofclasses && (
						<FormFeedback>{formErrors.course_noofclasses}</FormFeedback>
					)}
				</InputContainer>

				<InputContainer>
					<Input
						required
						type="text"
						placeholder="Option 1 Value"
						name="course_option1value"
						value={formData?.course_option1value || null}
						onChange={handleChange}
						disabled={selectCourse}
					/>
					{formErrors.course_option1value && (
						<FormFeedback>{formErrors.course_option1value}</FormFeedback>
					)}
				</InputContainer>

				<InputContainer>
					<Input
						required
						type="text"
						placeholder="Option 2 Value"
						name="course_option2value"
						value={formData?.course_option2value || null}
						onChange={handleChange}
						disabled={selectCourse}
					/>
					{formErrors.course_option2value && (
						<FormFeedback>{formErrors.course_option2value}</FormFeedback>
					)}
				</InputContainer>

				<InputContainer>
					<Input
						required
						type="text"
						placeholder="Course Fee"
						name="course_fee"
						value={formData?.course_fee || null}
						onChange={handleChange}
					/>
					{formErrors.course_fee && (
						<FormFeedback>{formErrors.course_fee}</FormFeedback>
					)}
				</InputContainer>

				<DueDateContainer>
					<OptionListContainer>
						<OptionList
							selected={formData?.course_open}
							onClick={() => handleOptionClick("course_open", true)}
						>
							Open
						</OptionList>

						<OptionList
							selected={!formData?.course_open}
							onClick={() => handleOptionClick("course_open", false)}
						>
							Closed
						</OptionList>
					</OptionListContainer>
				</DueDateContainer>
				{/* {error && <p>{error}</p>} */}
			</FormContainer>
			<FixedBottomContainer>
				{!selectCourse ? (
					<SubmitButton onClick={handleClick}>Create</SubmitButton>
				) : (
					<SubmitButton onClick={handleClick}>Save</SubmitButton>
				)}
			</FixedBottomContainer>
		</>
	);
};
