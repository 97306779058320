import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import {
	ContentContainer,
	EventWrapper,
	EventTextWrapper,
	EventTitle,
	EventDate,
	EventPicWrapper,
	EventPic,
	TableContainer,
	Table,
	TableHeader,
	TableRow,
	TableCell,
	ProgramCell,
	PendingStatus,
	ConfirmedStatus,
} from "../../styles/Admin";

const Students = ({ data }) => {
	console.log(data);

	return (
		<ContentContainer>
			<TableContainer>
				<Table>
					<thead>
						<TableRow>
							<TableHeader>Student #</TableHeader>
							<TableHeader>Name of Student</TableHeader>
							<TableHeader>Gender</TableHeader>
							<TableHeader>Date of Birth</TableHeader>
							{/* Add other headers here */}
						</TableRow>
					</thead>
					<tbody>
						{data?.map((student) => (
							<TableRow key={student.student_id}>
								<TableCell>
									<Link
										to={`/eventAdmin/admin/student/id/${student.student_id}`}
									>
										{student.student_id}
									</Link>
								</TableCell>
								<TableCell>{student.name}</TableCell>
								<TableCell>{student.gender}</TableCell>
								<TableCell>{student.dateofbirth}</TableCell>
								{/* Add other cells here */}
							</TableRow>
						))}
					</tbody>
				</Table>
			</TableContainer>
		</ContentContainer>
	);
};

export default Students;
