import React, { useRef, useState, useEffect } from "react";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import { TopbarInsideModule } from "../../components/topbar/TopbarInsideModule.jsx";
import OrdersByCourse from "../../components/orders/OrdersByCourse.jsx";

import StickyTopTab from "../../components/stickyTopTab/StickyTopTab.jsx";

import axios from "axios";

import { BodyContainer2 } from "../../styles/GlobalStyles.jsx";

import { SubmitButton } from "../../styles/SignUp.js";

import {
	ContentContainer,
	ClassInfoWrapper,
	ClassTitle,
	ClassDetailsStyled,
	DetailValue,
	Label,
	OptionListPrice,
	Subheader,
	ImageContainer,
	StatusContainer,
	StatusText,
	LoadingOverlay,
	LoadingSpinner,
	ModalBackground,
	ModalContent,
	BookingStatusStyled,
} from "../../styles/Admin";

const CourseBookingDetails = () => {
	const [data, setData] = useState({});
	const [orderDetails, setOrderDetails] = useState({});
	const [filterCriteria, setFilterCriteria] = useState("course.course_id");
	const [filter, setFilter] = useState("");
	const [sortField, setSortField] = useState("id");
	const [sortOrder, setSortOrder] = useState("ASC");
	const [loadingCircle, setLoadingCircle] = useState(false);

	const [currentTab, setCurrentTab] = useState("All Orders");

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	// const fields = queryParams.get("field").split(",");
	// const id = location.pathname.split("/")[4];

	const { id } = useParams();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await axios.get(
					`/eventAdmin/api/orders/courses?filter=${filterCriteria}&value=${id}&sort=${sortField}&order=${sortOrder}`
				);

				// const modifiedData = res.data.map((event) => {
				// 	const datetimeArray = JSON.parse(event.datetime);

				// 	datetimeArray.forEach((element) => {
				// 		const date = element.date.substring(0, 10);
				// 		return { ...element, date: date };
				// 	});
				// });
				setData(res.data[0]);
				setOrderDetails(res.data[0].orderDetails);
			} catch (err) {
				console.log(err);
			}
		};

		fetchData();
		// setResultChange("All");

		// const intervalId = setInterval(fetchData, 1000);

		// Clean up the interval when the component unmounts or when dependencies change
		// return () => {
		// 	clearInterval(intervalId);
		// };
	}, [filterCriteria, filter]);

	return (
		<>
			<TopbarInsideModule
				location={`${data?.course_code}`}
				goBack={true}
				link={`/eventAdmin/admin/orders/courses`}
			/>

			<BodyContainer2>
				<ContentContainer>
					<ClassInfoWrapper>
						<Label> {data?.course_code}</Label>
						<ClassTitle>{data?.name}</ClassTitle>

						<ClassDetailsStyled>
							<DetailValue> {data?.course_option1value}</DetailValue>
							<DetailValue> {data?.course_option2value}</DetailValue>
							<DetailValue>
								{" "}
								Total {data?.course_noofclasses} classes
							</DetailValue>
						</ClassDetailsStyled>

						{/* <OptionListPrice>
							{data?.fee === "0" ? "Free" : `HK$ ${data?.fee}`}
						</OptionListPrice> */}
					</ClassInfoWrapper>

					<ClassInfoWrapper>
						<Subheader>Course Enrollment</Subheader>
						<BookingStatusStyled>
							<DetailValue>
								Enrolled: {data?.actual_enrollment}/{data?.course_capacity}
								{data?.actual_enrollment === data?.course_capacity && (
									<div style={{ color: "red" }}>Full</div>
								)}
							</DetailValue>
						</BookingStatusStyled>
						<OrdersByCourse
							data={orderDetails && orderDetails.length > 0 ? orderDetails : []}
						/>
					</ClassInfoWrapper>
				</ContentContainer>
			</BodyContainer2>

			{/* <SearchSortBar /> */}
			{/* // <ProgramChangingContent tab={currentTab} data={data} /> */}

			{/* {modalOpen && (
				<MessageModal
					isOpen={modalOpen}
					template={modalTemplate}
					language={"en"}
					autoClose={autoClose}
					setModalOpen={setModalOpen}
					formData={formData}
					setFormData={setFormData}
					handleSubmit={handleSubmit}
					selectCourse={selectCourse}
				/>
			)} */}
		</>

		// 	{/***************************************
		//     // LOADING CIRCLE //
		// ***************************************/}
		// 	{loadingCircle && (
		// 		<LoadingOverlay>
		// 			<LoadingSpinner />
		// 		</LoadingOverlay>
		// 	)}
		// // </>
	);
};

export default CourseBookingDetails;
