import styled from "styled-components";
import { Link } from "react-router-dom";

/*********************************/
//  BOTTOM NAV   //
/*********************************/

export const BottomNavContainer = styled.div`
	width: 100%;
	z-index: 1 !important;
	position: fixed;
	bottom: 0;
	background-color: #ffffff;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
	overflow: hidden;
	user-select: none; //Prevent text selection
`;

export const BottomNav = styled.div`
	// box-shadow: 0px -3px 2px -2px rgba(0, 0, 0, 0.38);
	max-width: 978px; /* Default maximum width */
	width: 100%;
	height: 68px;
	/* must match body margin 55px*/
	background-color: #ffffff;
	display: flex;
	justify-content: space-between;
	overflow-x: auto;
	/* support for smaller screen devices */
`;

export const NavLink = styled(Link)`
	display: flex;
	flex-direction: column;
	/*Ensures text under icon */
	align-items: center;
	justify-content: center;
	flex-grow: 1;
	flex: 1;
	/* nav links takes up even space*/
	min-width: 50px;
	overflow: hidden;
	/* No scroll */
	white-space: nowrap;
	/* Single line & doesn't expand*/
	font-size: 13px;
	padding-top: 8px;
	padding-bottom: 8px;
	color:  ${(props) => (props.selected ? "#465258" : "#d3d3d3")};
	text-decoration: none;
	/*removes underline on text links*/
	-webkit-tap-highlight-color: transparent;
	/*removes default blue on mobile devices*/
	transition: background-color 0.1s ease-in-out;
	background-color: #ffffff

	&:hover {
		color: #465258;
	}



	svg {
		flex: 1;
		fill: ${(props) => (props.selected ? "#465258" : "#d3d3d3")};
		
	}


	&:hover svg {
		fill: #465258;
	}

	.nav__text{
	color:  ${(props) => (props.selected ? "#465258" : "#d3d3d3")};
	}


`;

export const NavItem = styled.div`

display: flex;
	flex-direction: column;
	/*Ensures text under icon */
	align-items: center;
	justify-content: center;
	flex-grow: 1;
	flex: 1;
	/* nav links takes up even space*/
	min-width: 50px;
	overflow: hidden;
	/* No scroll */
	white-space: nowrap;
	/* Single line & doesn't expand*/
	font-size: 13px;
	padding-top: 8px;
	padding-bottom: 8px;
	color:  ${(props) => (props.selected ? "#465258" : "#d3d3d3")};
	text-decoration: none;
	/*removes underline on text links*/
	-webkit-tap-highlight-color: transparent;
	/*removes default blue on mobile devices*/
	transition: background-color 0.1s ease-in-out;
	background-color: #ffffff

	&:hover {
		color: #465258;
	}



	svg {
		flex: 1;
		fill: ${(props) => (props.selected ? "#465258" : "#d3d3d3")};
		
	}


	&:hover svg {
		fill: #465258;
	}

	.nav__text{
	color:  ${(props) => (props.selected ? "#465258" : "#d3d3d3")};
	}


`;

export const NavLinkAdd = styled(Link)`
	display: flex;
	flex-direction: column;
	/*Ensures text under icon */
	align-items: center;
	justify-content: center;
	// flex-grow: 1;
	flex: 1;
	height: 100%;
	bottom: 30px;
	// width: 130px; // Set width
	// height: 130px; // Set height equal to width to form a circle
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: ${(props) => (props.selected ? "#465258" : "#d3d3d3")};
	text-decoration: none;
	/*removes underline on text links*/
	-webkit-tap-highlight-color: transparent;
	/*removes default blue on mobile devices*/
	transition: background-color 0.1s ease-in-out;
	background-color: #ffffff;

	& > * {
		// flex: 1;
		// border: 1px solid red;
	}

	&:hover {
		color: #465258;
	}

	svg {
		margin-top: -10px;
		width: 50%; // Adjust SVG size within the circle
		fill: ${(props) => (props.selected ? "#465258" : "#d3d3d3")};
		stroke: ${(props) =>
			props.selected
				? "#ffffff"
				: "#ffffff"}; // Adjust stroke color based on selection
		stroke-width: 3px; // Set the stroke width
	}

	&:hover svg {
		fill: #465258;
	}

	.nav__text {
		font-size: 9px;
		color: ${(props) => (props.selected ? "#ffffff" : "#ffffff")};
		// overflow-wrap: break-word; // Allows the words to break and wrap onto the next line
		// word-wrap: break-word; // An older synonym for overflow-wrap
		// white-space: normal; // Ensures that whitespace is handled like in typical block containers
	}

	// Media Queries to adjust width and height at different breakpoints
	@media (max-width: 600px) {
		width: 15vw;
		height: 15vw; // Keep height equal to width
		min-width: 80px;
		min-height: 80px; // Keep min-height equal to min-width
	}

	@media (max-width: 400px) {
		width: 20vw;
		height: 20vw; // Keep height equal to width
		min-width: 80px;
		min-height: 80px; // Keep min-height equal to min-width
	}
`;

//Edit Program
export const SaveButton = styled.button`
	padding: 3px;
	border: none;
	background-color: teal;
	color: white;
	cursor: pointer;
	width: 90%;
	border-radius: 4px;
	height: 50px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 20px;
	margin: auto;

	svg {
		width: 15px;
		fill: white;
	}
`;
