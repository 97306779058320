import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BackArrow from "../../assets/BackArrow.js";
import { TopbarInsideModule } from "../../components/topbar/TopbarInsideModule.jsx";
import MessageModal from "../../components/modal/Modal.jsx";

import {
	Calender,
	Clock,
	UpArrow,
	DownArrow,
	RightArrow,
	LeftArrow,
} from "../../assets/Calender.js";

import { BodyContainer2 } from "../../styles/GlobalStyles.jsx";

import {
	FormContainer,
	InputContainer,
	Input,
	TextArea,
	SubmitButton,
	FormFeedback,
	InputHorizontalContainer,
	GroupInputContainer,
	FormLabel,
} from "../../styles/SignUp.js";

import {
	DateContainer,
	TimeContainer,
	StyledDatePicker,
	StyledDateTime,
	FixedBottomContainer,
	OptionListContainer,
	OptionList,
	OptionListIconContainer,
	OptionListInput,
	ModuleContainer,
	DueDateContainer,
	StepContainer,
	OptionListValueContainer,
} from "../../styles/Admin.jsx";

import axios from "axios";

import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";

const CreateProgram = () => {
	const navigate = useNavigate();

	const [formErrors, setFormErrors] = useState({});

	const [modalOpen, setModalOpen] = useState(false);
	const [modalMessage, setModalMessage] = useState("");

	const [selectedOption, setSelectedOption] = useState({
		option1: "One Day",
		option2: "Open",
	});

	const minDate = new Date();

	const [formData, setFormData] = useState({
		name: "",
		code: "",
		category: "",
		description: "",
		open: true,
		duedate: new Date().toISOString().slice(0, 10),
	});

	const [isDueDateCalenderOpen, setIsDueDateCalenderOpen] = useState(false);

	const CustomDueDateInput = React.forwardRef(
		({ value, onClick, index }, ref) => (
			<input
				type="text"
				style={{
					width: "100%",
					height: "40px",
					color: "#ffffff",
					textAlign: "right",
					// position: "absolute",
					// top: "0",
					// right: "10px",
					// bottom: "-13px",
					border: "none",
					backgroundColor: "transparent",
				}}
				required
				name="duedate"
				value={formData.duedate}
				readOnly
				onClick={(event) => {
					event.preventDefault();
					setIsDueDateCalenderOpen(!isDueDateCalenderOpen);
					onClick(event);
				}}
				onBlur={(event) => {
					setIsDueDateCalenderOpen(false);
				}}
				ref={ref}
			/>
		)
	);

	const validateForm = () => {
		const errors = {};

		if (!formData.name) {
			errors.name = "Name is required";
		}

		if (!formData.code) {
			errors.code = "Programe code is required";
		}

		// if (!formData.duedate) {
		// 	errors.duedate = "Date is required";
		// }

		// if (!formData.venue) {
		// 	errors.venue = "Venue is required";
		// }

		// if (!formData.instructor) {
		// 	errors.instructor = "Instructor is required";
		// }

		if (!formData.category) {
			errors.category = "Category is required";
		}

		setFormErrors(errors);
		return Object.keys(errors).length === 0; // Valid form if no errors/ return true or false
	};

	const handleChange = (event) => {
		const { name, value, dataset } = event.target;

		let formattedValue = value;
		if (name === "duedate") {
			setIsDueDateCalenderOpen(!isDueDateCalenderOpen);
			formattedValue = value.toISOString().slice(0, 10);
		}

		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: formattedValue,
		}));
	};

	const handleSubmit = async (e) => {
		console.log(formData);

		e.preventDefault();
		const isValid = validateForm();

		if (isValid) {
			try {
				const res = await axios.post(
					"/eventAdmin/api/class/createProgram",
					formData
				);
				console.log(res.data);
				setModalOpen(true);
				setModalMessage("Sucessfully Created");

				setTimeout(() => {
					navigate(`/eventAdmin/admin/program/${res.data.programId}`);
				}, 3000);
			} catch (err) {
				setFormErrors(err.response.data);
			}
		} else {
			console.log("Form is invalid! Not submitting.", formErrors);
		}
	};

	const handleOptionClick = (optionName, optionValue) => {
		setSelectedOption((prev) => ({
			...prev,
			[optionName]: optionValue,
		}));
	};

	return (
		<>
			<TopbarInsideModule
				location="Create Programme"
				goBack={false}
				link="/eventAdmin/admin/home"
			/>
			<BodyContainer2>
				<ModuleContainer>
					<FormContainer>
						<InputContainer>
							<Input
								required
								type="text"
								placeholder="Programme Name"
								name="name"
								value={formData.name}
								onChange={handleChange}
							/>
							{formErrors.name && (
								<FormFeedback>{formErrors.name}</FormFeedback>
							)}
						</InputContainer>

						<InputContainer>
							<Input
								required
								type="text"
								placeholder="Programme Code"
								name="code"
								value={formData.code}
								onChange={handleChange}
							/>
							{formErrors.code && (
								<FormFeedback>{formErrors.code}</FormFeedback>
							)}
						</InputContainer>
						<InputContainer>
							<Input
								required
								type="text"
								placeholder="Programme Category"
								name="category"
								value={formData.category}
								onChange={handleChange}
							/>
							{formErrors.category && (
								<FormFeedback>{formErrors.category}</FormFeedback>
							)}
						</InputContainer>

						<DueDateContainer>
							<OptionListContainer>
								<OptionList
									selected={selectedOption.option2 === "Open"}
									onClick={() => handleOptionClick("option2", "Open")}
								>
									Open Until
									<DateContainer>
										<StyledDatePicker
											selected={new Date(formData.duedate)}
											onChange={(date) =>
												handleChange({
													target: { name: "duedate", value: date },
												})
											}
											dateFormat="MM/dd/yyyy"
											minDate={minDate}
											customInput={<CustomDueDateInput />}
											open={isDueDateCalenderOpen}
										/>
									</DateContainer>
									{formErrors.duedate && (
										<FormFeedback>{formErrors.duedate}</FormFeedback>
									)}
								</OptionList>

								<OptionList
									selected={selectedOption.option2 === "closed"}
									onClick={() => handleOptionClick("option2", "closed")}
								>
									Closed
								</OptionList>
							</OptionListContainer>
						</DueDateContainer>
						<InputContainer>
							<TextArea
								required
								type="text"
								placeholder="Course Description"
								name="description"
								value={formData.description}
								onChange={handleChange}
							/>
							{formErrors.description && (
								<FormFeedback>{formErrors.description}</FormFeedback>
							)}
						</InputContainer>

						{/* {error && <p>{error}</p>} */}
					</FormContainer>
					<FixedBottomContainer>
						<SubmitButton onClick={handleSubmit}>Create</SubmitButton>
					</FixedBottomContainer>

					{modalOpen && (
						<MessageModal
							isOpen={modalOpen}
							message={modalMessage}
							autoClose={true}
						/>
					)}
				</ModuleContainer>
			</BodyContainer2>
		</>
	);
};

export default CreateProgram;
