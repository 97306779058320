import React, { useRef, useState, useEffect } from "react";
import StickyTopTab from "../../components/stickyTopTab/StickyTopTab.jsx";
import ChangingData from "../../components/orders/ChangingData.jsx";
import SearchSortBar from "../../components/orders/SearchSortBar.jsx";
import OrdersByCourses from "../../components/orders/OrdersByCourses.jsx";
import axios from "axios";
import { BodyContainer2 } from "../../styles/GlobalStyles";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import { LoadingOverlay, LoadingSpinner } from "../../styles/GlobalStyles.jsx";

const OrdersCourses = () => {
	const TabContext = React.createContext();
	const location = useLocation();
	const [currentTab, setCurrentTab] = useState("Course Enrollment");
	const [tabValues, setTabValues] = useState([
		{
			name: "tab_1",
			label: "All Bookings",
			link: "/eventAdmin/admin/orders",
		},
		{
			name: "tab_2",
			label: "Course Enrollment",
			link: "/eventAdmin/admin/orders/courses",
		},
	]);

	// Set currentTab based on the current URL path
	useEffect(() => {
		const matchingTab = tabValues?.find(
			(tab) => tab.link === location.pathname
		);
		if (matchingTab) {
			setCurrentTab(matchingTab.label);
		}
	}, [location.pathname, tabValues]);

	const [data, setData] = useState([]);
	const [filterCriteria, setFilterCriteria] = useState("id");
	const [filter, setFilter] = useState("");
	const [sortField, setSortField] = useState("id");
	const [sortOrder, setSortOrder] = useState("ASC");
	const [loadingCircle, setLoadingCircle] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await axios.get(
					`/eventAdmin/api/orders/courses?filter=${filterCriteria}&value=${filter}&sort=${sortField}&order=${sortOrder}`
				);

				// const modifiedData = res.data.map((event) => {
				// 	const datetimeArray = JSON.parse(event.datetime);

				// 	datetimeArray.forEach((element) => {
				// 		const date = element.date.substring(0, 10);
				// 		return { ...element, date: date };
				// 	});
				// });
				setData(res.data);
			} catch (err) {
				console.log(err);
			}
		};

		fetchData();
		// setResultChange("All");

		// const intervalId = setInterval(fetchData, 1000);

		// Clean up the interval when the component unmounts or when dependencies change
		// return () => {
		// 	clearInterval(intervalId);
		// };
	}, []);

	const handleFilterChange = (name) => {
		console.log(name);
		if (name === "All Orders") {
			setFilter("");
			setFilterCriteria("id");
		} else {
			if (name === "Pending Orders") {
				setFilter(0);
				setFilterCriteria("status");
			}
		}
	};

	return (
		<>
			<TabContext.Consumer>
				{(tab) => (
					<>
						{tabValues ? (
							<StickyTopTab
								tabValues={tabValues}
								tabValue={tab}
								changeTabAction={handleFilterChange}
								setCurrentTab={setCurrentTab}
								currentTab={currentTab}
							/>
						) : null}

						{/* <SearchSortBar /> */}
						{/* <ChangingData data={data} /> */}
					</>
				)}
			</TabContext.Consumer>

			{/* Flight related content */}
			{data && <OrdersByCourses data={data} />}
			{/*************************************** 
            // LOADING CIRCLE //
        ***************************************/}
			{loadingCircle && (
				<LoadingOverlay>
					<LoadingSpinner />
				</LoadingOverlay>
			)}
		</>
	);
};

export default OrdersCourses;
