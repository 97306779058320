import React from "react";
import {
	ContentContainer,
	EventWrapper,
	EventTextWrapper,
	EventTitle,
	EventDate,
	EventPicWrapper,
	EventPic,
	TableContainer,
	Table,
	TableHeader,
	TableRow,
	TableCell,
	ProgramCell,
	PendingStatus,
	ConfirmedStatus,
	CancelStatus,
	FixedBottomContainer,
} from "../../styles/Admin";
import { Link, useNavigate } from "react-router-dom";

const OrdersByCourse = ({ data, selectCourse, setSelectCourse }) => {
	const navigate = useNavigate();
	console.log(data);
	return (
		<ContentContainer>
			<TableContainer>
				<Table>
					<thead>
						<TableRow>
							<TableHeader>Status</TableHeader>
							<TableHeader>Last Name</TableHeader>
							<TableHeader>First Name</TableHeader>
							<TableHeader>Gender</TableHeader>
							<TableHeader>Date of birth</TableHeader>
							<TableHeader>Order ID</TableHeader>
							<TableHeader>Contact</TableHeader>

							{/* Add other headers here */}
						</TableRow>
					</thead>
					<tbody>
						{data?.map((course) => (
							<TableRow
								key={course.order_id}
								// onClick={() => {
								// 	navigate(
								// 		`/eventAdmin/admin/orders/courses/${course.course_id}`
								// 	);
								// }}
								// style={
								// 	selectCourse === course
								// 		? { backgroundColor: "lightgray" }
								// 		: {}
								// }
							>
								<TableCell>
									{course.order_status === 1 ? (
										<ConfirmedStatus>Confirmed</ConfirmedStatus>
									) : course.order_status === 0 ? (
										<PendingStatus>Pending</PendingStatus>
									) : (
										<CancelStatus>Cancelled</CancelStatus>
									)}
								</TableCell>
								<TableCell>{course.participant_info?.lastname}</TableCell>
								<TableCell>{course.participant_info?.firstname}</TableCell>
								<TableCell>{course.participant_info?.gender}</TableCell>
								<TableCell>{course.participant_info?.dob}</TableCell>
								<TableCell>{course.order_id}</TableCell>
								<TableCell>
									<ProgramCell>
										<p>{course.contact}</p>
										<p>{course.email}</p>
									</ProgramCell>
								</TableCell>
								{/* Add other cells here */}
							</TableRow>
						))}
					</tbody>
				</Table>
			</TableContainer>
		</ContentContainer>
	);
};

export default OrdersByCourse;
