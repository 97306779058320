import React, { useState, useEffect } from "react";
import {
	TopBarWrapper,
	TopNavBar,
	TopNavLeft,
	TopNavCenter,
	TopNavRight,
	STickyTopBarWrapper,
} from "./TopbarStyles.jsx";

import { useNavigate, useLocation, useParams } from "react-router-dom";

import logo from "../../assets/DMGdigi-logo.png";

import { LogoContainer, Logo } from "../../styles/SignUp";

import BackArrow from "../../assets/BackArrow.js";

import { CrossX, Scanner } from "../../assets/Calender.js";

export const TopbarInsideModule = ({ location, goBack, link, icon }) => {
	const navigate = useNavigate();
	const locationPath = useLocation();
	const queryParams = new URLSearchParams(locationPath.search);

	// const fields = queryParams.get("field").split(",");
	// const id = location.pathname.split("/")[4];

	const fromPath = queryParams.get("from");

	const [previousPath, setPreviousPath] = useState(locationPath.pathname);

	useEffect(() => {
		setPreviousPath(locationPath.pathname);
	}, [locationPath.pathname]);

	return (
		<STickyTopBarWrapper>
			<TopNavBar>
				{goBack && (
					<TopNavLeft>
						<BackArrow onClick={() => navigate(link)} />
					</TopNavLeft>
				)}
				<TopNavCenter> {location}</TopNavCenter>
				{!goBack && (
					<TopNavRight>
						{fromPath ? (
							<CrossX onClick={() => navigate(fromPath)} />
						) : (
							<CrossX onClick={() => navigate(link)} />
						)}
					</TopNavRight>
				)}
				{icon === "scanner" && (
					<TopNavRight>
						<Scanner
							onClick={() =>
								navigate(
									`/eventAdmin/admin/checkin/scanner?from=${encodeURIComponent(
										previousPath
									)}`
								)
							}
						/>
					</TopNavRight>
				)}
			</TopNavBar>
		</STickyTopBarWrapper>
	);
};
