import { createGlobalStyle, keyframes } from "styled-components";
import styled from "styled-components";

/////////////////////////////////////////////////////////////////////////////
// //Example to CUSTOMIZE DOM ALTERATION TO 'Container' FROM 'GlobalStyles'
// const CustomContainer = styled(Container)`
//   height: calc(92vh - 200px);
// `;
/////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////
// //Example Clamp:
//font-size: clamp(min-size, preferred-size, max-size);
///////////////////////////////////////////////////////

export const GlobalStyles = createGlobalStyle`
  body {
    // margin: auto;
    padding: 0;
    font-family: sans-serif;
    background-color: #465258;
    // height: 100vh;
    transition: background-color 0.3s ease; /* Transition effect */
      // position: relative;
overflow-x: hidden;

}


* {
    margin:0; 
    padding: 0; 
    box-sizing: border-box;
}



  a {
    color: #fff; /* Sets the color of the link */
    text-decoration: none;
  }

  a:visited {
    color: #fff; /* Sets the color of visited links */
  }

input, select, textarea {
    font-size: 16px !important; // Set font size to 16px to prevent zooming on mobile

  }

  /* Media queries for different screen sizes */
  @media (max-width: 978px) {
    body {
      background-color: #CCD1D1;
    }
  }

  @media (max-width: 834px) {
    body {
      background-color:#99A3A4;
    }
  }

  @media (max-width: 768px) {
    body {
      background-color:#7A8283;
    }
  }

  @media (max-width: 576px) {
    body {
      background-color: #808B96;
    }
  }

  @media (max-width: 480px) {
    body {
      background-color:#808B96;
    }
  }

  @media (max-width: 320px) {
    body {
      background-color: #34495E;
    }
  }
`;

export const BodyContainer = styled.div`
	height: 100vh;
	position: relative;
	z-index: 1;
`;

export const Container = styled.div`
	position: fixed;
	display: flex;
	flex-direction: column; /* Set flex direction to column */
	background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
	box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.2);
	min-width: 978px; /* Default minimum width */
	width: 90%; /* Default width */
	max-width: 978px;

	transition: background-color 0.3s ease; /* Transition effect */
	height: calc(100% - 180px); /* Subtract Topnav height */
	border-radius: 8px;
	margin: auto;
	margin-top: 120px; /* Offset for the Topnav */
	padding-bottom: 50px;
	// margin-bottom:80px;
	height: auto;
	overflow-y: scroll;
	overflow-x: hidden;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 999;

	/* Media queries 160px inbetween sizes */
	@media (max-width: 978px) {
		min-width: 820px;
		max-width: 820px;
	}

	@media (max-width: 820px) {
		min-width: 768px;
		max-width: 768px;
	}

	@media (max-width: 768px) {
		min-width: 576px;
		max-width: 608px;
	}

	@media (max-width: 576px) {
		min-width: 420px;
		max-width: 480px;
		margin-top: 80px; /* Offset for the Topnav */
	}

	@media (max-width: 420px) {
		min-width: 375px;
		max-width: 380px;
		margin-top: 80px; /* Offset for the Topnav */
	}

	@media (max-width: 375px) {
		min-width: 360px;
		max-width: 360px;
		margin-top: 80px; /* Offset for the Topnav */
	}
`;

export const TopBarContainer = styled.div`
	position: sticky;
	top: 0;
	z-index: 1;
	// background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
	// border: 1px solid red;
	height: 100px;
	color: #fff;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
`;

export const Top = styled.div`
	flex: 1;
	width: 100%;
	border: 1px solid red;
	color: #fff;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	& > * {
		flex: 1;
		border: 1px solid blue;
	}
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const TopTitle = styled.div`
	position: relative;
	text-align: center;
	font-size: clamp(28px, 1vw, 3.125rem);
	text-transform: uppercase;
	color: black;
	font-family: serif;
	font-weight: 400;
	letter-spacing: 1px;
	margin: 1rem auto;
	border-bottom: none;
	user-select: none; //Prevent text selection
	z-index: 1; // to ensure it stays on top of other content
	/* Add the animation */
	animation: ${fadeIn} 1s ease-in-out;
	max-width: 400px;

	@media (max-width: 480px) {
		font-size: 18px;
		max-width: 250px;
	}
`;

export const TopTitleNameColor = styled.span`
	color: #1f618d;
`;

//Require import of component:
//import ClickBackArrow from "../";
export const ClickBackArrowHolder = styled.div`
	// position: absolute;
	padding: 0.8rem;
	/* margin: 0.8rem; */
	// z-index: 2; // to ensure it stays on top of other content
`;

//Require import of component:
//import ClickForwardArrow from "../";
export const ClickForwardArrowHolder = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	margin: 0.8rem;
	z-index: 2; // to ensure it stays on top of other content
`;

export const ErrorMessageContainer = styled.div`
	padding: 10px;
	width: 100%;
	text-align: center;
`;

export const FixedContainer = styled.div`
	position: relative;
	width: 100%;
	// height: 100vh; // this makes the container fill the viewport
	// overflow-y: auto;  // this enables scrolling within the container
	overflow-y: scroll;
	overflow-x: hidden;
	// border: 10px solid blue;
	height: ${(props) => props.height}px; /* Use dynamic height */
`;

export const BodyContainer2 = styled.div`
	// display: flex;
	// flex-direction: column; /* Set flex direction to column */
	min-width: 978px; /* Default minimum width */
	width: 100%; /* Default width */
	max-width: 978px;
	// height: 100%; /* Subtract Topnav height */
	// min-height:60vh;
	// s
	margin: auto;
	margin-bottom: 50px;
	// overflow-y: scroll;
	// overflow-x: hidden;
	padding: 0px 0px;
	// height: 800px;

	background-color: #465258;
	position: relative;
	// top: 0;
	// left:0;
	// right:0;
	// z-index: ;
	overflow: auto;
	overflow-y: auto;

	/* Media queries 160px inbetween sizes */
	@media (max-width: 978px) {
		min-width: 100vw;
		max-width: 820px;
	}

	@media (max-width: 820px) {
		min-width: 100vw;
		max-width: 768px;
	}

	@media (max-width: 768px) {
		min-width: 100vw;
		max-width: 608px;
	}

	@media (max-width: 576px) {
		min-width: 100vw;
		max-width: 480px;
	}

	@media (max-width: 420px) {
		min-width: 100vw;
		max-width: 380px;
	}

	@media (max-width: 375px) {
		min-width: 100vw;
		max-width: 360px;
	}
`;

export const TopBarTab = styled.div`
	width: 100%;
	max-width: 978px;
	margin: 0px auto;
	padding: 5px 0;
	// margin-bottom: 15px;
	// border:10px solid blue;
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	background-color: #fff;

	position: sticky; // Add this line
	top: 0; // And this line
	z-index: 999;

	@media (max-width: 576px) {
		margin: 0;
		padding: 0px 0px 30px 0px;
	}
`;

export const TopBarTabButton = styled.button`
	width: 100%;
	border: none;
	height: 50px;
	background-color: transparent;
	color: rgba(0, 0, 0, 0.4);
	font-size: clamp(18px, 2vw, 2rem);
	border-radius: 0;
	min-width: 100px;
	padding: 0px 5px;
	border-bottom: 2px solid rgba(0, 0, 0, 0.1);
	cursor: pointer;
	transition: all 0.3s ease-in-out;

	//Acts like an 'active' tab
	color: ${(props) =>
		props.$active ? "rgba(0, 0, 0, 1)" : "rgba(0, 0, 0, 0.4)"};
	border-bottom: ${(props) =>
		props.$active
			? "2px solid rgba(0, 0, 0, 1)"
			: "2px solid rgba(0, 0, 0, 0.1)"};
`;

////////////////////////////////////////////
// LOADING CIRCLE //
////////////////////////////////////////////
const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

export const LoadingOverlay = styled.div`
	z-index: 99999999999999999999 !important;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const LoadingSpinner = styled.div`
	border: 8px solid #f3f3f3;
	border-top: 8px solid #3498db;
	border-radius: 50%;
	width: 60px;
	height: 60px;
	animation: ${spin} 2s linear infinite;
`;
