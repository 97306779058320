import styled from "styled-components";
import DatePicker from "react-datepicker";
import DateTime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import "react-datepicker/dist/react-datepicker.css";
import { keyframes } from "styled-components";

/*********************************/
// Welcome  //
/*********************************/

export const WelcomeBox = styled.div`
	width: 100%;
	background-color: none;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin: auto;
	overflow: hidden;
	padding: 10px;
	border-radius: 10px;
	// border: 1px solid #fff;
	color: #fff;
	height: 100px;
	font-size: 2rem;
`;

/*********************************/
// Module Container  //
/*********************************/
export const ModuleContainer = styled.div`
	width: 100%;
	position: relative;
	background-color: #ffffff;
	padding: 20px 10px;
	padding-bottom: 200px;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
	margin-top: 30px;
	// border: 1px solid red;
`;

/*********************************/
// Date & Time Picker //
/*********************************/

export const DateContainer = styled.div`
	position: relative;

	.react-datepicker__header {
		// background-color: red !important;
	}

	.react-datepicker {
		color: #000;
		display: block;
		position: absolute;
		// top: 0;
		right: 50%;
		// left: 0;
		margin: auto;
		background-color: #ffffff !important;
	}
`;

export const DueDateContainer = styled.div`
	width: 100%;
	// position: relative;

	.react-datepicker__header {
		// background-color: red !important;
	}

	.react-datepicker {
		color: #000;
		display: block;
		position: absolute;
		// top: 0;
		right: -200px;
		// left: 0;
		// margin: auto;
		// z-index: 99999999999999999 !important;
		// background-color: red !important;
	}
`;

export const StyledDatePicker = styled(DatePicker)`
	position: relative;
`;

export const StyledDateTime = styled(DateTime)``;

export const TimeContainer = styled.div`
	.rdtPicker {
		position: absolute;
		top: 0;
		right: 0;
	}
	width: 100%;
`;

export const StepsWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-direction: row;
`;

export const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	width: 100%; // Or any specific value you want
`;

export const ButtonCreateWrapper = styled.div`
	width: 200px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

/*********************************/
//FORM BUTTON //
/*********************************/

export const StickyWrapper = styled.div`
	position: sticky; // Add this line
	top: 0; // And this line
	z-index: 999;
`;

export const FixedBottomContainer = styled.div`
	position: fixed;
	bottom: 0;
	right: 0;
	left: 0;
	display: flex;
	flex-direction: row;
	justify-content: flex-between;
	align-items: center;
	margin: auto;
	// border: 1px solid red;
	//   height:30px;
	width: 100%; /* Default width */
	max-width: 978px;
	background-color: teal;
	z-index: 99999999999999999999999999999999999999999999999999999999999999999 !important;

	& > * {
		flex: 1;
	}
`;

export const StepContainer = styled.div`
	font-weight:bold
	text-align:center !important;
	background-color: teal;
	color: white;
	padding:3px;
	display:flex;
	align-items:center;
	justify-content:center;
	height:50px;

`;

/*********************************/
//Events //
/*********************************/
// Define your styled components
export const ContentContainer = styled.div`
	position: sticky;
	// top: 80px;
	min-height: 88vh;
	height: 100%;
	// overflow-y: hidden;
	// overflow-x: auto;
	margin: auto;
	// z-index: 9999;
	background-color: #ffffff;
	width: 100%;
	padding: ${(props) => (props.padding ? props.padding : "0px")};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	gap: 10px;
`;

export const CourseWrapper = styled.div`
	// border: 1px solid red;
	margin: auto;
	background-color: #ffffff;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	// padding: 5px;

	svg {
		width: 20px;
	}
`;

export const CourseWrapperLeft = styled.div`
	flex: 1;
`;

export const CourseWrapperCenter = styled.div`
	flex: 3;
`;

export const CourseWrapperRight = styled.div`
	flex: 1;
	text-align: right;
`;

export const DetailsContainer = styled.div`
	position: relative;
	display: grid;
	width: 100%;
	grid-gap: 10px;
	grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
	margin: 0px auto;
	border: 1px solid #abb2b9;
	border-radius: 10px;
	margin-top: 10px;
`;

export const DetailsContainerItem = styled.div`
	width: 100%;
	// border: 1px solid #abb2b9;
	border-radius: 10px;
	padding: 3px 3px;
	height: 60px;
	// text-align: left;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	// border: 1px solid red;
`;

export const DetailsLabel = styled.label`
	font-size: 12px;
	color: grey;
`;

export const EventWrapper = styled.div`
	border-bottom: 5px solid grey;
	margin: auto;
	background-color: #ffffff;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px;
`;

export const EventImgWrapper = styled.div`
	position: relative;
	width: 100%;
	height: 300px;
	// border: 1px solid red;
`;

export const EventImgIcon = styled.div`
	position: absolute;
	bottom: 10px;
	right: 10px;
	border: 1px solid #ffffff;
	border-radius: 50%;
	width: 50px;
	height: 50px;
	background-color: lightgrey;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	svg {
		width: 40px;
	}
`;

export const EventImg = styled.div`
	width: 100%;
	height: 100%;
	background: url(${(props) => props.src}) center center/cover no-repeat;
	// border: 1px solid red;
`;

export const EventTextWrapper = styled.div`
	flex: 3;
`;

export const EventTitle = styled.div`
	font-weight: bold;
`;
export const EventDate = styled.div`
	color: grey;
`;

export const EventPicWrapper = styled.div`
	flex: 1;
`;

export const EventPic = styled.img`
	width: 100%;
`;

export const ScrollableDiv = styled.div`
	height: 100%; /* Or set a specific height */
	max-height: 75vh;
	overflow-x: auto;
	overflow-y: auto;
	// border: 10px solid yellow;
`;

export const InfoContainer = styled.div`
	width:100%;
	display:flex;
	    flex-direction: row;
	justify-content:space-between;
	align-items:center
	gap:10px;
	padding: 15px 15px;
   border-bottom:1px #ccc solid;

`;

export const InfoContainerVertical = styled.div`
	width:100%;
	display:flex;
	    flex-direction: column;
	justify-content:space-between;
	align-items:center
	gap:10px;
	padding: 15px 15px;
//    border:1px #ccc solid;
`;

export const InfoItem = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	border: 1px red yellow;

	svg {
		width: 20px;
	}
`;

export const InfoItemName = styled.div`
	font-weight: bold;
`;

export const SessionWrapper = styled.div`
   width:100%
   border:1px solid red;
`;

export const SessionTitle = styled.div`
	font-weight: bold;
	font-size: 18px;
	margin: 15px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;

	svg {
		width: 30px;
	}
`;

export const SubHeader = styled.div`
	font-size: 14px;
	margin-top: 10px;
`;

export const InfoItemDetails = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
`;

//FORM

// .product-variation .selected-result{
//     display: none;
//     /* // border: 1px solid red; */

// }

export const OptionListContainer = styled.div`
	position: relative;
	display: grid;
	width: 100%;
	grid-gap: 10px;
	grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
	margin: 0px auto;
	// border: 1px solid red;
`;

export const OptionList = styled.div`
	width: 100%;
	border: 1px solid #abb2b9;
	border-radius: 10px;
	padding: 3px 10px;
	height: 50px;
	text-align: center;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	transition: all 0.2s ease-in-out;
	cursor: pointer;
	// border: 1px solid red;
	background-color: ${(props) => (props.selected ? "#465258" : "#ffffff")};
	color: ${(props) => (props.selected ? "#ffffff" : "#000000")};
`;

export const OptionListValueContainer = styled.div`
	flex: 2;
	width: 100%;
	// border: 1px solid blue;
	text-align: center;
`;

export const OptionListIconContainer = styled.div`
	flex: 1;
	width: 100%;
	// border: 1px solid red;

	svg {
		width: 30%;
		fill: #ffffff;
	}
`;

export const OptionListInput = styled.input`
	border: none;
	background: none;
	outline: none;
	box-shadow: none;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	// display: block;
	// border: 1px solid red;
	color: #ffffff;
	width: 100%;
	flex: 1;
	text-align: center;
	// margin-left: 20px;
`;

// Modal
export const ModalBackground = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const ModalContent = styled.div`
	background: white;
	padding: 20px;
	border-radius: 10px;
	max-width: 500px;
	width: 90%;
`;

////////////////////////////////////////////
// LOADING CIRCLE //
////////////////////////////////////////////
const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

export const LoadingOverlay = styled.div`
	z-index: 99999 !important;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const LoadingSpinner = styled.div`
	border: 8px solid #f3f3f3;
	border-top: 8px solid #3498db;
	border-radius: 50%;
	width: 60px;
	height: 60px;
	animation: ${spin} 2s linear infinite;
`;

// export const TitleContainer = styled.div`
// 	width: 100%;
// 	border: 1px solid #abb2b9;
// 	border-radius: 10px;
// 	padding: 3px 10px;
// 	height: 50px;
// 	text-align: left;
// 	display: flex;
// 	flex-direction: row;
// 	justify-content: space-between;
// 	align-items: center;
// 	transition: all 0.2s ease-in-out;
// 	cursor: pointer;
// 	// border: 1px solid red;
// 	background-color: ${(props) => (props.selected ? "#465258" : "#ffffff")};
// 	color: ${(props) => (props.selected ? "#ffffff" : "#000000")};
// `;

// .product-option .medium{

// flex:2;
// display:flex;
// align-items:center;
// justify-content:center;
// flex-direction:row;

// }

// .medium .variationText{
// flex:1;
// /* border:1px solid red; */
// width:100%;
// }

// .product-option .notReady {
//     opacity: 0.3 !important;
//     background-color: grey !important;
//     /* // opacity:0.5 !important;
//     // color: var(--darkgrey-color) !important; */
//     pointer-events: none !important;
//     position: relative !important;

// }

// .product.notValid {
//     opacity:0.3 !important;
//     background-color: $light-color !important;
//     // opacity:0.5 !important;
//     // color: var(--darkgrey-color) !important;
//     pointer-events: none !important;
//     position: relative !important;

// }

// .product.notValid:after {
//     position: absolute;
//     content: '';
//     background: grey;
//     opacity: 1;
//     display: block;
//     width: 70%;
//     height: 1px;
//     -webkit-transform: rotate(-15deg);
//     transform: rotate(-15deg);
//     /* //center the X vertically and horizontally: */
//     left: 0;
//     right: 0;
//     top: 0;
//     bottom: 0;
//     margin: auto;
// }

//Booking Orders

export const OrdersContainer = styled.div`
	border: 1px solid #ccc;
	padding: 20px;
	background-color: #f9f9f9;
`;

// .bookingOrders {

// }

// Search & Sort Bar

export const SearchSortForm = styled.form`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
	margin: 20px auto;
	padding: 0 10px;

	label {
		color: #fff;
		display: block;
	}

	// @media (max-width: 768px) {
	// 	min-width: 608px;
	// 	width: 608px;
	// }

	@media (max-width: 768px) {
		flex-direction: column;
		align-items: flex-start;
	}

	// @media (max-width: 420px) {
	// 	min-width: 300px;
	// 	width: 300px;
	// }

	// @media (max-width: 375px) {
	// 	min-width: 300px;
	// 	width: 300px;
	// }
`;

export const SearchDiv = styled.div`
	flex: 1;
	display: flex;
	gap: 10px;
	border: 1px solid red;

	@media (max-width: 768px) {
		width: 100%;
	}
`;

export const SortDiv = styled.div`
	flex: 1;
	display: flex;
	justify-content: flex-end;
	gap: 10px;
	border: 1px solid red;
	text-align: right;
`;

export const SearchSortSelect = styled.select`
	padding: 5px;
	margin-left: 10px;
`;

export const SearchSortInput = styled.input`
	padding: 5px;
	flex: 1;
`;

export const SearchSortButton = styled.button`
	padding: 5px;
`;

// Order Table
export const TableContainer = styled.div`
	overflow-x: auto !important;
	width: 100%;
	// overflow-y: auto;
	// border: 10px solid red;
	position: relative;
`;

export const Table = styled.table`
	width: 100%;
	border-collapse: collapse;
	position: relative;
	top: 0;
	left: 0;
	transform: translate3d(0, 0, 0); // Promote the table to its own layer

	th,
	td {
		min-width: 150px; // Adjust this value as needed
	}
`;

export const TableHeader = styled.th`
	border: 1px solid #ddd;
	padding: 8px;
	text-align: left;
	background-color: #f2f2f2;
`;

export const TableRow = styled.tr`
	&:nth-child(even) {
		background-color: #f2f2f2;
	}

	a,
	a:visited {
		color: inherit;
		text-decoration: none;
	}
`;

export const TableCell = styled.td`
	border: 1px solid #ddd;
	padding: 8px;
`;

export const ProgramCell = styled.td`
	display: flex;
	flex-direction: column;
	align-items: start; // Align items to the start of the cell
	justify-content: center; // Center items vertically
	// Add other styles as needed
`;

// Status
export const StatusContainer = styled.div`
	padding: 10px;
	width: 100%;
	margin-bottom: 10px;
	color: white;
	text-align: center;
	background-color: ${(props) => {
		switch (props.status) {
			case 1:
				return "green";
			case 9:
				return "red";
			case 0:
				return "orange";
			default:
				return "teal";
		}
	}};
`;

export const PendingStatus = styled(StatusContainer)`
	background-color: orange;
`;

export const CancelStatus = styled(StatusContainer)`
	background-color: red;
`;

export const ConfirmedStatus = styled(StatusContainer)`
	background-color: green;
`;

export const CheckedInStatus = styled(StatusContainer)`
	background-color: white;
	border: 1px solid green;
	color: #000000;
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		width: 30px;
		height: 30px;
	}
`;

// Order

export const CardContent = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	min-height: 100vh;
	padding-bottom: 100px;
`;

// export const ButtonWrapper = styled.div`
// 	position: absolute;
// 	top: 22px;
// 	left: 22px;
// 	z-index: 10;
// `;

export const NoteTitle = styled.div`
	font: 800 32px Lora, sans-serif;
	text-align: center; // Center text
	margin-top: 20px;
	margin-bottom: 0px; // Adjust spacing after title
	color: #0c729e;
	font-style: italic;
`;

export const Note = styled.div`
	font: 800 15px Lora, sans-serif;
	text-align: center; // Center text
	margin-top: 0px;
	margin-bottom: 0px; // Adjust spacing after title
	color: #0c729e;
	font-style: italic;
`;

export const ClassInfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	background-color: #ffffff;
	width: 100%;
	padding: 10px 10px;
	// border-radius: 0 0 20px 20px;
	border-bottom: 15px solid lightgrey;
`;

export const ClassTitle = styled.h1`
	// border: solid black 1px;
	// background-color: #fff;
	max-width: 400px;
	height: auto;
	font: 700 18px Poppins, sans-serif;
	text-align: left; // Center text
	margin: 10px 0;
	// padding: 0 20px;
`;

export const ClassDetailsStyled = styled.div`
	// display: grid;
	// grid-template-columns: 1fr 3fr; // Defines two columns: 1st column is 1/4th, 2nd column is 3/4ths of the total width
	// gap: 10px; // Gap between grid items
	width: 100%; //Makes the grid 80% of the width of the parent container
	padding-bottom: 10px;
`;

export const DetailItem = styled.div`
	display: contents; // Makes the container behave like a direct child of the grid container
`;

// export const DetailLabel = styled.span`
//   color: var(--Text-Muted, gray);
//   font-family: Poppins, sans-serif;
//   font-size: 14px;
//   font-weight: 600;
//   color: #eee;
// `;

export const DetailValue = styled.div`
	font-family: Lora, sans-serif;
	width: 100%;
	color: black;
	font-weight: 500;
	font-size: 16px;
	// text-decoration: underline;
	font-style: italic;
`;

const FooterWrapper = styled.div`
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	border-radius: 12px 12px 0 0;
	background-color: var(--color-white);
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	padding: 11px;
	gap: 20px;
	z-index: 1000;
	box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1); // Very thin drop shadow
	@media (min-width: 500px) {
		width: 500px; /* Apply max-width constraint */
		left: calc((100vw - 500px) / 2);
		right: calc((100vw - 500px) / 2);
	}
`;

export const InputForm = styled.form`
	/* border: solid red 3px; */
	padding: 0px 20px 0px 20px;
	margin: 0;
	align-self: stretch;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 10px;
`;

export const InputBoxWrapper = styled.div`
	align-self: stretch;
	border-radius: var(--br-12xl-5);
	background-color: var(--color-whitesmoke);
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	padding: 0px var(--padding-mini) 0px var(--padding-base);
`;

export const InputBox = styled.input`
	height: 63px;
	flex: 1;
	position: relative;
	font-size: var(--font-size-lg);
	font-family: var(--font-poppins);
	color: var(--color-gray);
	text-align: left;
	display: flex;
	align-items: flex-start;
	z-index: 1;
	background-color: transparent;
	border: none;
	outline: none;
	padding: 0 var(--padding-mini);
	border-radius: var(--br-12xl-5);
	&:focus {
		outline: none;
	}
`;

export const Label = styled.div`
	font: 15px Lora, sans-serif;
	text-align: center; // Center text
	// margin-bottom: 20px; // Adjust spacing after venue
	// border:1px solid red;
	background-color: lightgrey;
	color: black;
	border-radius: 2px;
	padding: 2px 5px;
`;

export const OptionListPrice = styled.div`
	// width: 100%;
	// margin: 10px auto;
	text-align: left;
	font-size: 20px;
	font-weight: 800;
`;

export const Subheader = styled.div`
	font-weight: 700;
	font-size: 20px;
	// text-align: left; // Center text
	margin-top: 10px; // Adjust spacing after venue
	margin-bottom: 20px;
	// display:flex;
	// align-items:center;
	// justify-content:flex-start;
	border-left: 10px solid rgb(0, 0, 139);
	width: 100%;
	color: black;
	padding-left: 20px;
`;

export const DynamicallyChangeContent = styled.div`
	text-align: left; // Center text
	// margin-bottom: 20px; // Adjust spacing after venue
	// border:1px solid red;
	// background-color:rgba(0,0,0,0.5);
	color: black;
	// padding:2px 5px;
	width: 100%;
	height: 100%;
	position: absolute;

	ul {
		list-style-type: disc; // Use disc for bullet points
		padding-left: 20px; // Add some padding to indent the list
		font-size: 18px;
	}
`;

export const DynamicallyChangeContentContainer = styled.div`
	display: flex;
	overflow: hidden; /* Add this to hide the components that are off-screen */
	width: 100%;
	background-color: rgba(255, 242, 237, 1);
	border-radius: 15px;
	padding: 10px 10px;
	// border:1px solid red;
	// min-height:100px;
	height: 200px;
	position: relative;
	margin-top: 20px;
`;

export const OptionListTopContainer = styled.div`
	// flex: 2;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	// border: 1px solid blue;
`;

export const OptionListMiddleContainer = styled.div`
	color: grey;
	font-size: 15px;
	text-align: left;
	// border: 1px solid blue;
`;

export const OptionListTitle = styled.div`
	width: 100%;
	margin: 10px auto;
	text-align: left;
	font-size: 20px;
	font-weight: 600;
`;

export const ImageContainer = styled.div`
	width: 50%;
	margin: 10px auto;

	img {
		width: 100%;
	}
`;

// // Styled component for the text span
export const StatusText = styled.span`
	font-family: Poppins, sans-serif;
	font-size: 16px;
	font-weight: bold;
	padding-left: 0em;
	padding-right: 1em;
`;

export const DeleteWrapper = styled.div`
	background-color: #f8f9f9;
	padding: 1em;
	border-radius: 5px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
	margin-top: 3.5em;
`;

// Define the fadeIn keyframe animation
export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const IconTextContainer = styled.div`
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	width: 100%;
	font-size: 1.2rem;
	/* Add the animation */
	padding: 10px 0 10px 0;
	animation: ${fadeIn} 1s ease-in-out;
`;

export const IconText = styled.span`
	margin-left: 5px;
`;

export const BookingStatusStyled = styled.div`
	font-size: 28px; // Increase font size
	color: #ff0000; // Change color to red
	font-weight: bold; // Make the text bold
	margin-top: 10px;
	margin-bottom: 20px;
	padding: 10px; // Add some padding
	border: 2px solid ${(props) => props.color || "#ff0000"}; // Add a border
	border-radius: 5px; // Round the corners
	text-align: center; // Center the text
	width: 100%;
`;

export const CheckInStatus = styled.div`
	height: 20px;
	width: 20px;
	background-color: ${(props) => props.backgroundColor || "green"};
	border-radius: 50%;
`;

export const HighlightContainer = styled.div`
	width: 100%;
	padding: 10px;
	text-align: center;
	font-size: 20px;
	font-weight: 800;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	border: ${(props) =>
		props.hightlightBorder && `1px solid ${props.hightlightBorder}`};
`;
