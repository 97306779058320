import React, { useRef, useState, useEffect, useCallback } from "react";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import { TopbarInsideModule } from "../../components/topbar/TopbarInsideModule.jsx";

import StickyTopTab from "../../components/stickyTopTab/StickyTopTab.jsx";
import TopSlider from "../../components/photoSlider/Topslider";

import axios from "axios";

import { BodyContainer2 } from "../../styles/GlobalStyles.jsx";

import {
	ContentContainer,
	EventImgWrapper,
	EventImgIcon,
	EventImg,
	InfoContainer,
	InfoContainerVertical,
	InfoItem,
	InfoItemName,
	InfoItemDetails,
	CourseWrapper,
	CourseWrapperLeft,
	CourseWrapperCenter,
	CourseWrapperRight,
	DetailsContainer,
	DetailsContainerItem,
	DetailsLabel,
	SubHeader,
	SessionTitle,
	SessionWrapper,
	DeleteWrapper,
	IconTextContainer,
	IconText,
} from "../../styles/Admin.jsx";

import { InputContainer, FormLabel } from "../../styles/SignUp.js";

import { RightArrow, Camera, AddSVG } from "../../assets/Calender.js";

import ToggleStatusButton from "../../components/statusButton/ToggleStatusButton.jsx";

import IconDeleteOutline from "../../components/svg/IconDeleteOutline.js";

const Overview = ({ data, handleToggle, handleDelete }) => {
	console.log(data);

	return (
		<BodyContainer2>
			<ContentContainer>
				{/* <EventImgWrapper>
					<EventImgIcon>
						<Camera />
					</EventImgIcon>
					<EventImg
						src={
							process.env.PUBLIC_URL +
							"/upload_eventImage/11/4/wan-san-yip-7X60-5nr3Tk-unsplash.jpg"
						}
					></EventImg>
				</EventImgWrapper> */}

				{data?.images && <TopSlider images={data?.images} />}
				<InfoContainer>
					<InfoItem>
						<InfoItemName>Name</InfoItemName>
						<InfoItemDetails>
							<p>{data.name} </p>
							<p>{data.name_chi}</p>
						</InfoItemDetails>
					</InfoItem>
					<InfoItem>
						<Link to="./edit?field=name,name_chi">
							<RightArrow />
						</Link>
					</InfoItem>
				</InfoContainer>
				<InfoContainer>
					<InfoItem>
						<InfoItemName>Category</InfoItemName>
						<InfoItemDetails>{data.category}</InfoItemDetails>
					</InfoItem>
					<InfoItem>
						<Link to="./edit?field=category">
							<RightArrow />
						</Link>
					</InfoItem>
				</InfoContainer>
				<InfoContainer>
					<InfoItem>
						<InfoItemName>Venue</InfoItemName>
						<InfoItemDetails>
							<p>{data.venue}</p>
							<p>{data.venue_chi}</p>
						</InfoItemDetails>
					</InfoItem>
					<InfoItem>
						<Link to="./edit?field=venue,venue_chi">
							<RightArrow />
						</Link>
					</InfoItem>
				</InfoContainer>
				<InfoContainer>
					<InfoItem>
						<InfoItemName>Age</InfoItemName>
						<InfoItemDetails>{data.age}</InfoItemDetails>
					</InfoItem>
					<InfoItem>
						<Link to="./edit?field=age">
							<RightArrow />
						</Link>
					</InfoItem>
				</InfoContainer>

				<InfoContainer>
					<InfoItem>
						<InfoItemName>Description</InfoItemName>
						<InfoItemDetails>
							<p>{data.description}</p>
							<p>{data.description_chi}</p>
						</InfoItemDetails>
					</InfoItem>
					<InfoItem>
						<Link to="./edit?field=description,description_chi">
							<RightArrow />
						</Link>
					</InfoItem>
				</InfoContainer>
				{/* <InfoContainer>
					<InfoItem>
						<InfoItemName>Open application until</InfoItemName>
						<InfoItemDetails>{data.duedate}</InfoItemDetails>
					</InfoItem>
					<InfoItem>
						<Link to="./edit?field=duedate,open">
							<RightArrow />
						</Link>
					</InfoItem>
				</InfoContainer> */}

				<InfoContainer>
					<InfoItem>
						<InfoItemName>Active Status</InfoItemName>
					</InfoItem>
					<InfoItem>
						<ToggleStatusButton
							initialStatus={data?.status ? 1 : 0}
							handleToggle={handleToggle}
						/>
					</InfoItem>
				</InfoContainer>

				{/* <DeleteWrapper>
					<IconTextContainer onClick={handleDelete}>
						<IconDeleteOutline style={{ marginRight: "5px" }} />
						<IconText>Delete Program</IconText>
					</IconTextContainer> */}
				{/* <ButtonDeleteRound /> */}
				{/* onClick={() => handleModel("delete")} */}
				{/* </DeleteWrapper> */}
			</ContentContainer>
		</BodyContainer2>
	);
};

const Program = () => {
	const TabContext = React.createContext();

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	// const fields = queryParams.get("field").split(",");
	// const id = location.pathname.split("/")[4];

	const { id } = useParams();

	const [currentTab, setCurrentTab] = useState("Overview");
	const [tabValues, setTabValues] = useState([
		{
			name: "tab_1",
			label: "Overview",
			link: `/eventAdmin/admin/program/${id}`,
		},
		{
			name: "tab_2",
			label: "Courses",
			link: `/eventAdmin/admin/program/${id}/courses`,
		},
		// {
		// 	name: "tab_3",
		// 	label: "Classes",
		// },
	]);

	// Set currentTab based on the current URL path
	useEffect(() => {
		const matchingTab = tabValues?.find(
			(tab) => tab.link === location.pathname
		);
		if (matchingTab) {
			setCurrentTab(matchingTab.label);
		}
	}, [location.pathname, tabValues]);

	const navigate = useNavigate();

	const minDate = new Date();

	console.log(minDate);

	const [data, setData] = useState({});
	const [requireUpdateData, setRequireUpdateData] = useState(false);
	const [updatedData, setUpdatedData] = useState({});
	const [formErrors, setFormErrors] = useState({});
	const [loadingCircle, setLoadingCircle] = useState(false);
	const [fieldLabel, setFieldLabel] = useState([]);
	const [isDueDateCalenderOpen, setIsDueDateCalenderOpen] = useState(false);
	const [selectedOption, setSelectedOption] = useState({
		option1: "One Day",
		option2: "Open",
	});

	useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await axios.get(
					`/eventAdmin/api/class/getProgram?id=${id}`
				);

				setData(res.data);

				const fields = queryParams.get("field").split(",");
				for (let field of fields) {
					let fieldValue = res.data[field];

					if (field === "duedate") {
						fieldValue =
							moment(fieldValue).format("YYYY-MM-DD HH:mm:ss") ||
							moment(new Date())
								.hour(12)
								.minute(0)
								.second(0)
								.format("YYYY-MM-DD HH:mm:ss");
					}

					setUpdatedData((prev) => ({
						...prev,
						[field]: fieldValue,
					}));
				}
			} catch (err) {
				console.log(err);
			}
		};

		fetchData();
	}, [requireUpdateData]);

	const handleToggle = async () => {
		// const currentStatus = data.status ? 1 : 0;
		const newStatus = data.status === 1 ? 0 : 1;
		const newData = { status: newStatus };
		setRequireUpdateData(false);
		try {
			const res = await axios.put(
				`/eventAdmin/api/class/editProgram/${id}`,
				newData
			);

			setRequireUpdateData(true);
		} catch (err) {
			// setError(err.response.data);
		}
	}; // Add any dependencies of handleToggle here

	//Get the Program

	const handleFilterChange = (name) => {
		console.log(name);
		// setCurrentTab(name);

		// if (name === "All Events") {
		// 	setTabValues("");
		// } else {
		// 	setFilter(name);
		// }
	};

	const [modalOpen, setModalOpen] = useState(false);
	const [autoClose, setAutoClose] = useState(false);
	const [modalTemplate, setModalTemplate] = useState("");

	const handleDelete = async () => {
		console.log("delete");
	};

	return (
		<>
			<TopbarInsideModule
				location={data.name}
				goBack={true}
				link={`/eventAdmin/admin/programmes`}
			/>
			<TabContext.Consumer>
				{(tab) => (
					<>
						{tabValues ? (
							<StickyTopTab
								tabValues={tabValues}
								tabValue={currentTab}
								changeTabAction={handleFilterChange}
								currentTab={currentTab}
								setCurrentTab={setCurrentTab}
							/>
						) : null}
					</>
				)}
			</TabContext.Consumer>
			<Overview
				data={data}
				handleToggle={handleToggle}
				handleDelete={handleDelete}
			/>
			{/* <SearchSortBar /> */}
			{/* // <ProgramChangingContent tab={currentTab} data={data} /> */}

			{/* {modalOpen && (
				<MessageModal
					isOpen={modalOpen}
					template={modalTemplate}
					language={"en"}
					autoClose={autoClose}
					setModalOpen={setModalOpen}
					formData={formData}
					setFormData={setFormData}
					handleSubmit={handleSubmit}
					selectCourse={selectCourse}
				/>
			)} */}
		</>

		// 	{/***************************************
		//     // LOADING CIRCLE //
		// ***************************************/}
		// 	{loadingCircle && (
		// 		<LoadingOverlay>
		// 			<LoadingSpinner />
		// 		</LoadingOverlay>
		// 	)}
		// // </>
	);
};

export default Program;
