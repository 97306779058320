import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AddSVG from "../../assets/AddSVG.js";
import { Home, Event } from "../../assets/Calender.js";

import {
	BottomNav,
	NavLink,
	NavLinkAdd,
	BottomNavContainer,
	SaveButton,
} from "../../styles/BottomNavBar";

const StickyBottomSaveBar = ({ handleSubmit }) => {
	const page = useLocation().pathname.split("/")[3];

	return (
		<BottomNavContainer>
			{/* <!-- Bottom Nav Bar --> */}
			<BottomNav>
				<SaveButton onClick={handleSubmit}>Save</SaveButton>
			</BottomNav>
		</BottomNavContainer>
	);
};

export default StickyBottomSaveBar;
