import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TopBarTab, TopBarTabButton } from "../../styles/GlobalStyles";

import { useSwipeable } from "react-swipeable";

import TabContentComponent from "../tabContentComponent/TabContentComponent.jsx";

import { TabContext } from "../../registrationContext";

const StickyTopTab = ({
	tabValues,
	tabValue,
	changeTabAction,
	setCurrentTab,
	currentTab,
}) => {
	const navigate = useNavigate();
	const [tab, setTab] = useState(tabValues[0].label);
	console.log(currentTab);

	// const handleTabChange = (direction) => {
	// 	const currentIndex = tabValues.findIndex(
	// 		(tabValue) => tab === tabValue.label
	// 	);
	// 	let newIndex = currentIndex;

	// 	if (direction === "next" && currentIndex < tabValues.length - 1) {
	// 		newIndex = currentIndex + 1;
	// 	} else if (direction === "previous" && currentIndex > 0) {
	// 		newIndex = currentIndex - 1;
	// 	}

	// 	setTab(tabValues[newIndex].label);
	// };

	// const handlers = useSwipeable({
	// 	onSwipedLeft: () => handleTabChange("next"),
	// 	onSwipedRight: () => handleTabChange("previous"),
	// 	preventDefaultTouchmoveEvent: true,
	// 	trackMouse: false,
	// });

	const handleChangeTab = (e) => {
		// setTab(e.target.name);
		changeTabAction(e.target.name);
		setCurrentTab(e.target.name);
		console.log(e.target.name);
	};

	useEffect(() => {
		console.log(tab);
	}, [tab]);

	return (
		<TabContext.Provider value={currentTab}>
			<TopBarTab>
				{tabValues.map((tabValue) => (
					<TopBarTabButton
						key={tabValue.label}
						$active={currentTab === tabValue.label}
						onClick={() => {
							handleChangeTab({ target: { name: tabValue.label } });
							if (tabValue.link) {
								navigate(`${tabValue.link}`);
							}
						}}
					>
						{tabValue.label}
					</TopBarTabButton>
				))}
			</TopBarTab>
			{/* <TabContentComponent
				tab={tab}
				handleTab={handleTab}
			></TabContentComponent> */}
		</TabContext.Provider>
	);
};
export default StickyTopTab;
